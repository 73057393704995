import classNames from 'classnames'

import {useSelector} from '../../../../store.js'
import ShipperSelect from './ShipperSelect.js'
import WarehouseSelect from './WarehouseSelect.js'
import PickupDateTime from './PickupDateTime.js'
import {
  updateModalForm,
  modalFormSelector,
  errorsSelector,
  setPickupAddressSameAsAccount,
  pickupFedExCarrierCodesSelector,
  pickupFedExServiceCategoriesSelector,
  pickupFedExPickupDateTypesSelector,
  pickupFedExPackageLocationsSelector,
  FEDEX_GROUND_CARRIER_CODE,
} from './pickupModalFunctions.js'
import {isShipperNewFedExAuthSelector} from '../../../../data/shippers.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import Select from '../../../../common/components/Select.js'
import TextInput from '../../../../common/components/TextInput.js'

export default function FedExPickup() {
  const form = useSelector(modalFormSelector)
  const errors = useSelector(errorsSelector)
  const carrierCodes = useSelector(pickupFedExCarrierCodesSelector)
  const serviceCategories = useSelector(pickupFedExServiceCategoriesSelector)
  const pickupDateTypes = useSelector(pickupFedExPickupDateTypesSelector)
  const packageLocations = useSelector(pickupFedExPackageLocationsSelector)
  const isShipperNewFedExAuth = useSelector((state) =>
    isShipperNewFedExAuthSelector(state, {shipperID: form.shipperID}),
  )

  return (
    <div>
      <h3 className="fs-01">
        <strong>Pickup Info</strong>
      </h3>
      <ShipperSelect />
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-15">
          <label className="margin-bottom-5" htmlFor="id_use_warehouse">
            Pickup Location
          </label>
          {isShipperNewFedExAuth ? (
            <WarehouseSelect />
          ) : (
            <div className="flex">
              <div className="margin-right-20">
                <label className="fs-n0 unbold" htmlFor="id_use_warehouse">
                  <input
                    type="radio"
                    className="margin-bottom-5 margin-right-5"
                    name="id_use_warehouse"
                    id="id_use_warehouse"
                    checked={!form.pickupAddressSameAsAccount}
                    onChange={() => setPickupAddressSameAsAccount(false)}
                  />
                  <span>Use warehouse location</span>
                </label>
                {!form.pickupAddressSameAsAccount && <WarehouseSelect />}
              </div>
              <div>
                <label
                  className="fs-n0 unbold"
                  htmlFor="id_pickup_address_same_as_account"
                >
                  <input
                    type="radio"
                    className="margin-bottom-0 margin-right-5"
                    name="id_pickup_address_same_as_account"
                    id="id_pickup_address_same_as_account"
                    checked={form.pickupAddressSameAsAccount}
                    onChange={() => setPickupAddressSameAsAccount(true)}
                  />
                  <span>Use address on file with this account</span>
                </label>
              </div>
            </div>
          )}
        </li>
        <PickupDateTime />
        {isShipperNewFedExAuth && (
          <li className="list__item--form list__item--no-style">
            <Select
              label="Pickup Date Type"
              className="select--modal input--lg margin-bottom-0"
              id="id_pickup_date_type"
              value={form[`${FEDEX}__selectedPickupDateType`]}
              onChange={(value) =>
                updateModalForm(
                  {[`${FEDEX}__selectedPickupDateType`]: value},
                  {stickyProps: [`${FEDEX}__selectedPickupDateType`]},
                )
              }
              options={pickupDateTypes}
            />
          </li>
        )}
      </ul>
      <h3 className="fs-01 margin-top-30">
        <strong>Package Info</strong>
      </h3>
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-15">
          <Select
            label="Type of Pickup"
            className="select--modal w-25 margin-bottom-0"
            id="id_carrier_code"
            value={form[`${FEDEX}__selectedCarrierCode`]}
            onChange={(value) =>
              updateModalForm(
                {[`${FEDEX}__selectedCarrierCode`]: value},
                {stickyProps: [`${FEDEX}__selectedCarrierCode`]},
              )
            }
            options={carrierCodes}
          />
        </li>
        {form[`${FEDEX}__selectedCarrierCode`] ===
          FEDEX_GROUND_CARRIER_CODE && (
          <>
            <li className="list__item--form list__item--no-style margin-bottom-15">
              <Select
                label="Package Location"
                className="select--modal w-25 margin-bottom-0"
                id="id_package_location"
                value={form[`${FEDEX}__selectedPackageLocation`]}
                onChange={(value) =>
                  updateModalForm(
                    {[`${FEDEX}__selectedPackageLocation`]: value},
                    {stickyProps: [`${FEDEX}__selectedPackageLocation`]},
                  )
                }
                options={packageLocations}
              />
            </li>
            <li className="list__item--form list__item--no-style margin-bottom-15">
              <TextInput
                label="Oversize Package Count"
                className="input--h-29 input--sm margin-bottom-0"
                id="id_oversize_package_count"
                value={form.oversizePackageCount}
                onChange={(value) =>
                  updateModalForm({oversizePackageCount: value})
                }
                errorMessage={errors.oversizePackageCount}
              />
            </li>
          </>
        )}
        <li className="list__item--form list__item--no-style flex margin-bottom-15">
          {!isShipperNewFedExAuth && (
            <div className="margin-right-15">
              <Select
                label="Service Category"
                className="select--modal input--lg margin-bottom-0"
                id="id_service_category"
                value={form[`${FEDEX}__selectedServiceCategory`]}
                onChange={(value) =>
                  updateModalForm(
                    {[`${FEDEX}__selectedServiceCategory`]: value},
                    {stickyProps: [`${FEDEX}__selectedServiceCategory`]},
                  )
                }
                options={serviceCategories}
              />
            </div>
          )}
          <div
            className={classNames('margin-right-15', {
              error: errors.quantity,
            })}
          >
            <label className="clearfix" htmlFor="id_fedex_quantity">
              Quantity<span className="required">*</span>
            </label>
            <input
              type="text"
              id="id_fedex_quantity"
              className="input--text input--h-29 input--sm margin-bottom-0"
              value={form.quantity}
              onChange={(event) =>
                updateModalForm({quantity: event.target.value})
              }
            />
            {errors.quantity && (
              <small className="error error-message">{errors.quantity}</small>
            )}
          </div>
          <div
            className={classNames('margin-right-0', {
              error: errors.totalWeight,
            })}
          >
            <label className="clearfix" htmlFor="id_fedex_total_weight">
              Total Weight<span className="required">*</span>
            </label>
            <div>
              <input
                type="text"
                id="id_fedex_total_weight"
                className="input--text make-inline-block input--h-29 input--sm margin-right-5 margin-bottom-0"
                value={form.totalWeight}
                onChange={(event) =>
                  updateModalForm({totalWeight: event.target.value})
                }
              />
              <span className="fs-n0">lb</span>
            </div>
            {errors.totalWeight && (
              <small className="error error-message">
                {errors.totalWeight}
              </small>
            )}
          </div>
        </li>
        <li className="list__item--form list__item--no-style margin-bottom-15">
          <label className="clearfix" htmlFor="id_fedex_commodity_description">
            Description of Shipment Contents{' '}
            <span className="unbold">(optional)</span>
          </label>
          <input
            type="text"
            id="id_fedex_commodity_description"
            className="input--text input--h-29 input--90 margin-bottom-0"
            value={form.commodityDescription}
            onChange={(event) =>
              updateModalForm({commodityDescription: event.target.value})
            }
          />
        </li>
        <li className="list__item--form list__item--no-style margin-bottom-20">
          <label className="clearfix" htmlFor="id_fedex_remarks">
            Additional Pickup Instructions{' '}
            <span className="unbold">(optional)</span>
          </label>
          <input
            type="text"
            id="id_fedex_remarks"
            className="input--text input--h-29 input--90 margin-bottom-0"
            value={form.remarks}
            onChange={(event) => updateModalForm({remarks: event.target.value})}
          />
        </li>
        <li className="list__item--form list__item--no-style margin-bottom-5">
          <label className="unbold" htmlFor="id_has_overweight_packages">
            <input
              type="checkbox"
              className="margin-right-5"
              name="id_has_overweight_packages"
              id="id_has_overweight_packages"
              checked={form.isInternational}
              onChange={() =>
                updateModalForm(
                  {isInternational: !form.isInternational},
                  {stickyProps: ['isInternational']},
                )
              }
            />
            <span>This pickup will have international shipments</span>
          </label>
        </li>
      </ul>
    </div>
  )
}
