import {createSelector} from 'reselect'
import omitBy from 'lodash/omitBy.js'
import get from 'lodash/get.js'
import chunk from 'lodash/chunk.js'

import {
  OT_V3_ORDER,
  OT_RETURN_ORDER,
} from '../../common/constants/OrderTypes.js'
import {
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  PITNEY_CBDS,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../common/constants/ShipperNames.js'
import {FEDEX_SHIPPER_DEFAULT_HUB} from '../../common/constants/LabelConfig.js'
import {combineToOz} from '../../common/weight.js'
import {isEmptyValue} from '../../common/utils.js'
import {parseNonZeroPositiveNumber} from '../../common/parseNumbers.js'
import getShipDate from '../../common/getShipDate.js'
import {companySelector} from '../../data/company.js'
import {orderLinkSelector, updateOrders} from '../orders.js'
import {
  labelConfigSelector,
  labelShipperSelector,
  labelShipperTypeSelector,
  labelTypeSelector,
  getTotalPackageWeight,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  getMaxParcelCount,
  orderTypeSelector,
  orderNumberFromLabelConfigSelector,
  selectedRateSelector,
  assertAmazonPrimeSanity,
  referenceIDFromLabelConfigSelector,
  updateLabelInfo,
  saveLabelInfo,
  orderNumbersFromLabelConfigsSelector,
  getDimensionsParams,
  getInsuranceParams,
  getCanHaveHarmonizationCode,
  getCanHaveSKU,
  getCanHaveHarmonizationCodeCountry,
} from '../labelInfos/index.js'
import {getReturnLabelParams} from './rateRequest.js'
import {getState} from '../../store.js'
import apiverson from '../../common/apiverson.js'
import {updateReturnOrders} from '../../redux/actions/data/returnOrders.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {
  ORDER_DETAIL_PAGE,
  ORDER_LIST_PAGE,
  PACK_SHIP_PAGE,
} from '../../common/constants/Pages.js'
import {
  addPendingLabels,
  removePendingLabels,
} from '../../ordoro/OrderListPage/ActionPanel/orderActionPanelFunctions.js'
import {refreshOrderList} from '../../ordoro/OrderListPage/orderListActions.js'
import {loadOrderDetailOrder} from '../../ordoro/OrderDetailPage/orderDetailActions.js'
import {logError} from '../../common/error.js'
import {updateOrderCounts} from '../orderCounts.js'
import {showProcessLabelModal} from '../../ordoro/OrderListPage/Modals/ProcessLabelModal.js'

export function getCustomsParams(config, shipperType) {
  if (
    !config.attach_customs_info ||
    [AMAZON_SFP, AMAZON_SHIPPER, PITNEY_PRESORT, X_DELIVERY].includes(
      shipperType,
    )
  ) {
    return {}
  }

  const canHaveHarmonizationCode = getCanHaveHarmonizationCode(shipperType)
  const canHaveHarmonizationCodeCountry =
    getCanHaveHarmonizationCodeCountry(shipperType)
  const canHaveSKU = getCanHaveSKU(shipperType)

  const lines = config.customs_info.map((line) => ({
    description: line.description,
    quantity: Number(line.quantity),
    value: Number(line.value),
    weight: combineToOz(line.weightLB, line.weightOZ) / Number(line.quantity),
    country: line.country,
    ...(canHaveSKU && line.sku ? {sku: line.sku} : {}),
    ...(canHaveHarmonizationCode && line.harmonizationCode
      ? {harmonization_code: line.harmonizationCode}
      : {}),
    ...(canHaveHarmonizationCodeCountry && line.harmonizationCodeCountry
      ? {harmonization_code_country: line.harmonizationCodeCountry}
      : {}),
  }))

  return {
    customs_info: lines,
    ...(shipperType === FEDEX ? {etd_service: config.etd_service} : {}),
    ...(shipperType === UPS && config.purchase_order_number
      ? {purchase_order_number: config.purchase_order_number}
      : {}),
  }
}

export function getHazmatParams(parcel, shipperType) {
  if (shipperType !== UPS) {
    return {}
  }

  const boxShape = parcel[`${shipperType}__box_shape`]

  // Don't send hazmat for Letter (we already hide UI)
  if (boxShape === '01') {
    return {}
  }

  if (!parcel.hazmat_items) {
    return {}
  }

  return {
    hazmat_items: parcel.hazmat_items.map((hazmatItem) => ({
      commodity_regulated_level_code:
        hazmatItem[`${shipperType}__commodity_regulated_level_code`],
      regulation_set: hazmatItem[`${shipperType}__regulation_set`],
      transportation_mode: hazmatItem[`${shipperType}__transportation_mode`],
      technical_name: hazmatItem.technical_name,
      additional_description: hazmatItem.additional_description,
      class_division_number: hazmatItem.class_division_number,
      hazard_label_required: hazmatItem.hazard_label_required,
      id_number: hazmatItem.id_number,
      packaging_instruction_code: hazmatItem.packaging_instruction_code,
      packaging_group_type: hazmatItem[`${shipperType}__packaging_group_type`],
      packaging_type: hazmatItem.packaging_type,
      packaging_type_quantity: hazmatItem.packaging_type_quantity,
      proper_shipping_name: hazmatItem.proper_shipping_name,
      quantity: hazmatItem.quantity,
      reportable_quantity: hazmatItem.reportable_quantity,
      sub_risk_class: hazmatItem.sub_risk_class,
      uom: hazmatItem.uom,
      emergency_contact: hazmatItem.emergency_contact,
      emergency_phone: hazmatItem.emergency_phone,
    })),
  }
}

export function getSharedParams(
  config,
  shipper,
  labelType,
  shipFromAddress,
  shipToAddress,
) {
  const shipperType = shipper.vendor

  return {
    shipper_id: config.shipper_id,
    delivery_confirmation: config[`${shipperType}__delivery_confirmation`],
    shipping_method: config[`${shipperType}__shipping_method`],
    notify_bill_to: config.notify_bill_to,
    notify_ship_to: config.notify_ship_to,
    reference_number: config.reference_number,
    packing_list_id: config.packing_list_id,
    ship_from: shipFromAddress,
    ship_to: shipToAddress,
    ...getInsuranceParams(config, shipperType),
    ...getReturnLabelParams(labelType),
    ...getCustomsParams(config, shipperType),
    reference_id: config.reference_id,
  }
}

export function getPackagesParam(
  config,
  shipperType,
  otherParams = [{}],
  boxShapeOverride = null,
) {
  const maxParcelCount = getMaxParcelCount(shipperType)
  const packages = get(config, 'packages', [{}]).slice(0, maxParcelCount)

  return {
    packages: packages.map((parcel, index) => {
      const boxShape =
        shipperType === FEDEX &&
        boxShapeOverride &&
        parcel[`${shipperType}__box_shape`] === 'YOUR_PACKAGING'
          ? boxShapeOverride
          : parcel[`${shipperType}__box_shape`]

      return {
        ...omitBy(
          {
            box_shape: boxShape,
            weight: getTotalPackageWeight(parcel.weight, config),
            ...getDimensionsParams(parcel, boxShape, shipperType),
            ...getHazmatParams(parcel, shipperType),
          },
          isEmptyValue,
        ),
        ...get(otherParams, index, {}),
      }
    }),
  }
}

export function getBoxShapeParam(config, shipperType) {
  if ([NEWGISTICS, X_DELIVERY, SENDLE].includes(shipperType)) {
    return null
  }

  return config.boxShape
}

export function getTaxInfo(config, shipperType, property) {
  if (config[`${property}__tax_id_number`]) {
    return {
      [property]: {
        tax_id_number: config[`${property}__tax_id_number`],
        tax_id_type: config[`${shipperType}__${property}__tax_id_type`],
        ...([PITNEY, PITNEY_MERCHANT, DHL, DHL_ECOMMERCE].includes(shipperType)
          ? {
              tax_issuer_country: config[`${property}__tax_issuer_country`],
            }
          : null),
      },
    }
  }
}

export function getUpsParams(config) {
  return {
    certificate_origin: config.certificate_origin,
    saturday_delivery: config.saturday_delivery,
    direct_delivery: config.direct_delivery,
    shipper_return_to_address: config.use_alternate_return_to_address
      ? config.alternate_return_to_address
      : null,
    recipient_address_is_residential: config.recipient_address_is_residential,
    shipper_release: config.shipper_release,
    delivery_confirmation: config.can_have_delivery_confirmation
      ? config.ups__delivery_confirmation
      : null,
    package_bill_type: config.ups__package_bill_type,
    reason_for_export: config.ups__reason_for_export,
    ...getUpsPaymentParams(config),
    ...getUpsDutiesPaymentParams(config),
    ...getUpsMailInnovationsParams(config),
    ...getTaxInfo(config, UPS, 'sender_tax_info'),
    ...getTaxInfo(config, UPS, 'receiver_tax_info'),
    ...getPackagesParam(
      config,
      UPS,
      get(config, 'packages', []).map((parcel) => {
        const dryIceWeight = parseNonZeroPositiveNumber(parcel.dry_ice_weight)

        return omitBy(
          {
            additional_handling: parcel.additional_handling || null,
            declared_value: parseNonZeroPositiveNumber(
              parcel.ups__declared_value,
            ),
            dry_ice_weight: dryIceWeight,
            dry_ice_regulation_set: dryIceWeight
              ? parcel.dry_ice_regulation_set
              : null,
          },
          isEmptyValue,
        )
      }),
    ),
  }
}

export function getUpsPaymentParams(config) {
  if (config.ups__payment_type === 'BillShipper') {
    return {}
  }

  const params = {
    payment_type: config.ups__payment_type,
    payment_account: config.payment_account,
  }

  if (config.ups__payment_type === 'BillThirdParty') {
    Object.assign(params, {
      payment_zip: config.payment_zip,
      payment_country: config.payment_country,
    })
  }

  return params
}

export function getUpsDutiesPaymentParams(config) {
  if (config.ups__duties_payment_type === 'BillShipper') {
    return {}
  }

  const params = {
    duties_payment_type: config.ups__duties_payment_type,
    duties_payment_account: config.duties_payment_account,
  }

  if (config.ups__duties_payment_type === 'BillThirdParty') {
    Object.assign(params, {
      duties_payment_zip: config.duties_payment_zip,
      duties_payment_country: config.duties_payment_country,
    })
  }

  return params
}

export function getUpsMailInnovationsParams(config) {
  if (!config.is_mail_innovations) {
    return {}
  }

  return {
    usps_endorsement: config.ups__usps_endorsement,
    cost_center: config.cost_center,
  }
}

export function getFedExParams(config, rate, shipper) {
  // temp_use_fedex_auth
  const useFedExAuth = get(shipper, 'vendor_config.child_key')

  return {
    ship_date: getShipDate(config.ship_date),
    delivery_confirmation: config.fedex__delivery_confirmation,
    delivery_instructions: config.delivery_instructions,
    alternate_return_to_address: config.use_alternate_return_to_address
      ? config.alternate_return_to_address
      : null,
    recipient_address_is_residential: config.recipient_address_is_residential,
    certificate_of_origin: config.certificate_origin,
    saturday_delivery: config.saturday_delivery,
    ...(useFedExAuth
      ? {hold_at_location_id: config[`${FEDEX}__hold_at_location_id`]}
      : {hold_at_location: config.hold_at_location}),
    smart_post_hub:
      config.fedex__smart_post_hub === FEDEX_SHIPPER_DEFAULT_HUB
        ? get(shipper, 'vendor_config.smartpost_hub') || ''
        : config.fedex__smart_post_hub,
    smart_post_indicia: config.fedex__smart_post_indicia,
    smart_post_ancillary_endorsement: config.fedex__ancillary_endorsement,
    alcohol_shipment_license: config.fedex__alcohol_shipment_license,
    dangerous_goods_option: config.fedex__dangerous_goods_option,
    reason_for_export: config.fedex__reason_for_export,
    importer_of_record:
      config.attach_customs_info &&
      config.include_importer_of_record &&
      config.importer_of_record
        ? config.importer_of_record
        : null,
    pharmacy_delivery: config.pharmacy_delivery,
    priority_alert: get(shipper, 'vendor_config.has_priority_alert')
      ? config.fedex__priority_alert
      : null,
    label_type: config.fedex__label_type,
    etd_preshipment_docs: (config.fedex__etd_preshipment_docs || []).length
      ? config.fedex__etd_preshipment_docs
      : null,
    one_rate: useFedExAuth ? config.one_rate : null,
    ...getFedExPaymentParams(config),
    ...getFedExDutiesParams(config),
    ...getPackagesParam(
      config,
      FEDEX,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value: parseNonZeroPositiveNumber(
              parcel.fedex__declared_value,
            ),
            dry_ice_weight: parseNonZeroPositiveNumber(parcel.dry_ice_weight),
            invoice: config.invoice_number,
            purchase_order: config.purchase_order_number,
            department: config.department_number,
            sub_package_type: config.fedex__sub_package_type,
          },
          isEmptyValue,
        ),
      ),
      useFedExAuth && config.one_rate ? rate.box_type : null,
    ),
  }
}

export function getFedExPaymentParams(config) {
  const params = {
    payment_type: config.fedex__payment_type,
  }

  if (config.fedex__payment_type !== 'SENDER') {
    Object.assign(params, {
      payment_account: config.payment_account,
    })
  }

  return params
}

export function getFedExDutiesParams(config) {
  const params = {
    duties_payment_type: config.fedex__duties_payment_type,
  }

  if (config.duties_payment_type !== 'SENDER') {
    Object.assign(params, {
      duties_payment_account: config.duties_payment_account,
    })
  }

  return params
}

export function getEndiciaParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    contents_type: config.endicia__contents_type,
    hold_for_pickup: config.hold_for_pickup,
    nondelivery_option: config.endicia__nondelivery_option,
    mailing_zip_code: config.mailing_zip_code,
    ...getPackagesParam(config, ENDICIA),
  }
}

export function getPitneyParams(config, rate, company) {
  return {
    alternate_return_to_address: config.use_alternate_return_to_address
      ? config.alternate_return_to_address
      : null,
    ship_date: getShipDate(config.ship_date),
    non_delivery: config.pitney__nondelivery_option,
    reason_for_export: config.pitney__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    pitney_rate_type: company.pitney_rate_type,
    rate_cost: rate && rate.cost,
    mailing_zip_code: config.mailing_zip_code,
    ...getTaxInfo(config, PITNEY, 'sender_tax_info'),
    ...getPackagesParam(config, PITNEY),
  }
}

export function getPitneyMerchantParams(config, rate, company) {
  return {
    alternate_return_to_address: config.use_alternate_return_to_address
      ? config.alternate_return_to_address
      : null,
    ship_date: getShipDate(config.ship_date),
    non_delivery: config.pitney_merchant__nondelivery_option,
    reason_for_export: config.pitney_merchant__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    pitney_rate_type: company.pitney_rate_type,
    rate_cost: rate && rate.cost,
    mailing_zip_code: config.mailing_zip_code,
    ...getTaxInfo(config, PITNEY_MERCHANT, 'sender_tax_info'),
    ...getPackagesParam(config, PITNEY_MERCHANT),
  }
}

export function getPitneyPresortParams(config, rate) {
  return {
    non_delivery: config.pitney__nondelivery_option,
    reason_for_export: config.pitney__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    rate_cost: rate && rate.cost,
    mailing_zip_code: config.mailing_zip_code,
    ...getPackagesParam(config, PITNEY_PRESORT),
  }
}

export function getCanadaPostParams(config) {
  return {
    nondelivery_option: config.canada_post__nondelivery_option,
    reason_for_export: config.canada_post__reason_for_export,
    service_option: config.canada_post__service_option,
    invoice_number: config.invoice_number,
    ...getPackagesParam(config, CANADA_POST),
  }
}

export function getPitneyCBDSPostParams(config) {
  return {
    ...getPackagesParam(config, PITNEY_CBDS),
  }
}

export function getDHLParams(config) {
  return {
    nonstandard_day: config.dhl__nonstandard_day,
    nonstandard_contents: config.dhl__nonstandard_contents,
    signature_service: config.dhl__signature_service,
    is_dutiable: !!config.is_dutiable,
    federal_tax_id: config.federal_tax_id,
    state_tax_id: config.state_tax_id,
    ...(() => {
      const params = {
        shipping_payment_type: config.dhl__payment_type,
      }

      if (config.dhl__payment_type !== 'S') {
        Object.assign(params, {
          shipping_payment_account: config.payment_account,
        })
      }

      return params
    })(),
    ...(() => {
      const params = {
        duty_payment_type: config.dhl__duty_payment_type,
      }

      if (config.dhl__duty_payment_type !== 'S') {
        Object.assign(params, {
          duty_payment_account: config.duties_payment_account,
        })
      }

      return params
    })(),
    reason_for_export: config.dhl__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    foreign_trade_regulation: config.dhl__foreign_trade_regulation || null,
    internal_transaction_number: config.dhl__foreign_trade_regulation
      ? null
      : config.internal_transaction_number,
    ...getTaxInfo(config, DHL, 'sender_tax_info'),
    ...getTaxInfo(config, DHL, 'receiver_tax_info'),
    ...getPackagesParam(
      config,
      DHL,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value:
              parcel.dhl__declared_value && Number(parcel.dhl__declared_value),
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

export function getDHLEcommerceParams(config, labelType) {
  return {
    ship_date: getShipDate(config.ship_date),
    dangerous_goods: config[`${DHL_ECOMMERCE}__dangerous_goods`],
    delivery_confirmation: config[`${DHL_ECOMMERCE}__delivery_confirmation`],
    service_endorsement: config[`${DHL_ECOMMERCE}__service_endorsement`],
    return_reason: labelType === 'return' ? config.return_reason : null,
    ...getTaxInfo(config, DHL_ECOMMERCE, 'receiver_tax_info'),
    ...getPackagesParam(
      config,
      DHL_ECOMMERCE,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            description: parcel.description || '',
            declared_value: parseNonZeroPositiveNumber(
              parcel[`${DHL_ECOMMERCE}__declared_value`],
            ),
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

export function getAmazonParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    carrier_pickup: config.carrier_pickup,
    reference_number: undefined,
    ship_to: undefined,
    ...getPackagesParam(config, AMAZON_SFP),
  }
}

export function getAmazonShipperParams(config, rate) {
  return {
    recipient_address_is_residential: config.recipient_address_is_residential,
    ...(config.pickup_start_date && config.pickup_end_date
      ? {
          expected_service_offering: {
            start_date: config.pickup_start_date,
            end_date: config.pickup_end_date,
          },
        }
      : undefined),
    service_token: get(rate, 'service_token'),
    shipment_id: get(rate, 'shipment_id'),
    ...getPackagesParam(
      config,
      AMAZON_SHIPPER,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value: parseNonZeroPositiveNumber(
              parcel.amazon_shipper__declared_value,
            ),
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

export function getNewgisticsParams(config) {
  return {
    non_delivery: config.newgistics__nondelivery_option,
    enable_notifications: config.enable_notifications,
    dangerous_goods_option: config.newgistics__dangerous_goods,
    reason_for_export: config.newgistics__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    ...getPackagesParam(config, NEWGISTICS),
  }
}

export function getSendleParams(config) {
  const params = {
    pickup_date: config.pickup_date || undefined,
    reason_for_export: config[`${SENDLE}__reason_for_export`],
    ...getPackagesParam(
      config,
      SENDLE,
      get(config, 'packages', []).map((parcel) => ({
        description: parcel.description || '',
      })),
    ),
  }

  return params
}

export function getVisibleUSPSParams(config, rate) {
  return {
    ship_date: getShipDate(config.ship_date),
    rate_cost: rate && rate.cost,
    reason_for_export: config.visible_usps__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    ...getPackagesParam(config, VISIBLE_USPS),
  }
}

export function getAustraliaPostParams(config, rate) {
  return {
    ship_date: getShipDate(config.ship_date),
    service_token: get(rate, 'service_token'),
    shipment_id: get(rate, 'shipment_id'),
    reason_for_export: config.australia_post__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    ...getPackagesParam(config, AUSTRALIA_POST),
  }
}

export function getXDeliveryParams(config) {
  const params = {
    controlled_substance: config[`${X_DELIVERY}__controlled_substance`],
    ...getPackagesParam(
      config,
      X_DELIVERY,
      get(config, 'packages', []).map((parcel) => ({
        description: parcel.description || '',
        declared_value:
          parseNonZeroPositiveNumber(parcel.onelivex__declared_value) || 0,
      })),
    ),
  }

  return params
}

export function getLabelRequestParams(
  config,
  shipper,
  labelType,
  rate,
  company,
  shipFromAddress,
  shipToAddress,
) {
  const shipperType = shipper.vendor

  const sharedParams = getSharedParams(
    config,
    shipper,
    labelType,
    shipFromAddress,
    shipToAddress,
  )

  if (shipperType === UPS) {
    return {...sharedParams, ...getUpsParams(config)}
  }
  if (shipperType === FEDEX) {
    return {...sharedParams, ...getFedExParams(config, rate, shipper)}
  }
  if (shipperType === ENDICIA) {
    return {...sharedParams, ...getEndiciaParams(config)}
  }
  if (shipperType === PITNEY) {
    return {...sharedParams, ...getPitneyParams(config, rate, company)}
  }
  if (shipperType === PITNEY_MERCHANT) {
    return {...sharedParams, ...getPitneyMerchantParams(config, rate, company)}
  }
  if (shipperType === PITNEY_PRESORT) {
    return {...sharedParams, ...getPitneyPresortParams(config, rate, company)}
  }
  if (shipperType === CANADA_POST) {
    return {...sharedParams, ...getCanadaPostParams(config)}
  }
  if (shipperType === PITNEY_CBDS) {
    return {...sharedParams, ...getPitneyCBDSPostParams(config)}
  }
  if (shipperType === DHL) {
    return {...sharedParams, ...getDHLParams(config)}
  }
  if (shipperType === DHL_ECOMMERCE) {
    return {...sharedParams, ...getDHLEcommerceParams(config, labelType)}
  }
  if (shipperType === AMAZON_SFP) {
    return {...sharedParams, ...getAmazonParams(config)}
  }
  if (shipperType === AMAZON_SHIPPER) {
    return {...sharedParams, ...getAmazonShipperParams(config, rate)}
  }
  if (shipperType === AUSTRALIA_POST) {
    return {...sharedParams, ...getAustraliaPostParams(config, rate)}
  }
  if (shipperType === NEWGISTICS) {
    return {...sharedParams, ...getNewgisticsParams(config)}
  }
  if (shipperType === SENDLE) {
    return {...sharedParams, ...getSendleParams(config)}
  }
  if (shipperType === VISIBLE_USPS) {
    return {...sharedParams, ...getVisibleUSPSParams(config, rate)}
  }
  if (shipperType === X_DELIVERY) {
    return {...sharedParams, ...getXDeliveryParams(config)}
  }

  throw new Error(`Unexpected shipper: ${shipperType}`)
}

export const labelRequestSelector = createSelector(
  labelConfigSelector,
  labelShipperSelector,
  labelTypeSelector,
  selectedRateSelector,
  companySelector,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  (config, shipper, labelType, rate, company, shipFromAddress, shipToAddress) =>
    omitBy(
      getLabelRequestParams(
        config,
        shipper,
        labelType,
        rate,
        company,
        shipFromAddress,
        shipToAddress,
      ),
      isEmptyValue,
    ),
)

export function labelRequestURLSelector(state, {labelInfoID}) {
  const shipperType = labelShipperTypeSelector(state, {labelInfoID})
  const orderType = orderTypeSelector(state, {labelInfoID})

  let baseURL
  if (orderType === OT_V3_ORDER) {
    const orderNumber = orderNumberFromLabelConfigSelector(state, {
      labelInfoID,
    })
    baseURL = orderLinkSelector(state, {orderNumber})
  }
  if (orderType === OT_RETURN_ORDER) {
    baseURL = '/return_order'
  }

  return `${baseURL}/label/${encodeURIComponent(
    shipperType === PITNEY_MERCHANT ? PITNEY : shipperType,
  )}`
}

export async function createLabelImage(labelInfoID) {
  try {
    const labelRequestParams = labelRequestSelector(getState(), {labelInfoID})
    const orderType = orderTypeSelector(getState(), {labelInfoID})
    const shipperType = labelShipperTypeSelector(getState(), {labelInfoID})

    if (orderType === OT_V3_ORDER && shipperType !== X_DELIVERY) {
      delete labelRequestParams.ship_to

      assertAmazonPrimeSanity(labelInfoID, labelRequestParams)
    }

    const url = labelRequestURLSelector(getState(), {labelInfoID})

    await apiverson.post(url, labelRequestParams)

    if (orderType === OT_V3_ORDER) {
      const orderNumber = orderNumberFromLabelConfigSelector(getState(), {
        labelInfoID,
      })
      await updateOrders([orderNumber])
    }
    if (orderType === OT_RETURN_ORDER) {
      const referenceID = referenceIDFromLabelConfigSelector(getState(), {
        labelInfoID,
      })
      await updateReturnOrders([referenceID])
    }
  } catch (err) {
    const message = `Error creating label image: ${labelInfoID}. ${err.message}`
    updateLabelInfo(labelInfoID, {error_message: message})

    await saveLabelInfo(labelInfoID)
  } finally {
    updateLabelInfo(labelInfoID, {label_loading: false})
  }
}

export async function createLabelImages(
  labelInfoIDs,
  {displayProcessLabelModal = true} = {},
) {
  const currentPage = currentPageSelector(getState())

  displayProcessLabelModal =
    displayProcessLabelModal &&
    [ORDER_LIST_PAGE, ORDER_DETAIL_PAGE].includes(currentPage)

  try {
    if (displayProcessLabelModal) {
      showProcessLabelModal({labelInfoIDs})
    }

    addPendingLabels(labelInfoIDs)

    for (const labelInfoID of labelInfoIDs) {
      updateLabelInfo(labelInfoID, {label_loading: true})
    }

    const batchSize = 10
    const batches = chunk(labelInfoIDs, batchSize)
    for (const ids of batches) {
      await Promise.all(ids.map((labelInfoID) => createLabelImage(labelInfoID)))
    }

    if (currentPage === ORDER_LIST_PAGE) {
      await refreshOrderList()
    } else if (currentPage === ORDER_DETAIL_PAGE) {
      await loadOrderDetailOrder({reload: true})
    } else if (currentPage === PACK_SHIP_PAGE) {
      const orderNumbers = orderNumbersFromLabelConfigsSelector(getState(), {
        labelInfoIDs,
      })
      await updateOrders(orderNumbers)
    }
  } catch (err) {
    logError(err, 'Error creating label images')
  } finally {
    await updateOrderCounts()

    removePendingLabels(labelInfoIDs)
  }
}
