import {
  labelConfigSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import {FEDEX, UPS} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import {LABEL_PROPERTY_ATTACH_CUSTOMS_INFO} from '../SingleLabelConfigForm/AttachCustomsInfo.js'
import {useSelector} from '../../../store.js'

const LABEL_PROPERTY = 'purchase_order_number'
const VALID_SHIPPER_TYPES = [FEDEX, UPS]

function dependantPropertiesFunc(shipperType) {
  return shipperType === UPS
    ? [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
    : [LABEL_PROPERTY_SHIPPER_ID]
}

export default function PurchaseOrderNumber() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const {[LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]: attachCustomsInfo} = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID}),
  )

  return (
    <ConfigTextInput
      label="Purchase Order Number"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      dependantPropertiesFunc={dependantPropertiesFunc}
      hide={shipperType === UPS && !attachCustomsInfo}
    />
  )
}
