import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import {PluralBlock, Plural, plural} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setMO} from '../../../data/mos.js'
import {refreshMOList} from '../moListActions.js'

const MODAL_FORM = 'DELETE_MO_LINES_MODAL'

export function showDeleteMOLinesModal(referenceID, lineIDs) {
  setForm(MODAL_FORM, {
    referenceID,
    lineIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deleteMOLine(referenceID, lineID) {
  const {json} = await apiverson.delete(
    `/manufacturing_order/${encodeURIComponent(referenceID)}/line/${lineID}`,
  )

  setMO(json)
}

export async function deleteMOLines() {
  try {
    updateModalForm({isSaving: true})

    const {referenceID, lineIDs} = modalFormSelector(getState())
    const lineCount = lineIDs.length

    await Promise.all(
      lineIDs.map((lineID) => deleteMOLine(referenceID, lineID)),
    )

    showMessageToast(
      plural(lineCount)`${lineCount} MFG order line${[
        's were',
        ' was',
      ]} deleted.`,
    )

    closeModal()

    await refreshMOList()
  } catch (err) {
    updateModalForm({
      serverError: `Error deleting MFG Order line: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function DeleteMOLinesModal({form}) {
  const lineCount = form.lineIDs.length

  return (
    <ConfirmModal
      title={plural(lineCount)`Delete MFG Order Product${['s']}`}
      onConfirm={() => deleteMOLines()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <PluralBlock count={lineCount}>
        Are you sure you want to remove {lineCount} <Plural word="product" />{' '}
        from this MFG Order?
      </PluralBlock>
    </ConfirmModal>
  )
}

export default formConnect(DeleteMOLinesModal, modalFormSelector)
