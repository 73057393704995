import PropTypes from 'prop-types'

import {
  formSelector,
  getState,
  onlyIfAutoForm,
  useSelector,
} from '../../../../store.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../../common/constants/SettingsPanels.js'
import {BIGCOMMERCE} from '../../../../common/constants/CartVendorOptions.js'
import {redirect} from '../../../../common/location.js'
import {
  bigCommerceStoreURLSelector,
  cartSelector,
} from '../../../../data/carts.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  return baseCartErrorsSelector(state, {formName})
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return baseNewCartParamsSelector(state, {vendor: BIGCOMMERCE})
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    syncs_shipped_orders: !!cart.vendor_config.syncs_shipped_orders,
  }
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    syncs_shipped_orders: form.syncs_shipped_orders,
  }
}

export function authorize(
  formName,
  redirectURL = `/app${SALES_CHANNEL_SETTINGS_LINK}`,
) {
  const params = {
    redirect: redirectURL,
  }

  return redirect('', ['verde', 'bigcommerce', 'start'], params)
}

function BigCommerce({form, formName}) {
  const isNew = form.id === NEW_ID
  const storeURL = useSelector((state) =>
    bigCommerceStoreURLSelector(state, {cartID: form.id}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <>
              <div className="margin-top-15">
                <AuthorizeMessage formName={formName} />
              </div>
            </>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              {storeURL && (
                <FormListItem>
                  <div className="lh-sm margin-top-5">
                    <strong className="fs-00">Bigcommerce Store URL:</strong>
                  </div>
                  <a
                    href={storeURL}
                    className="fs-00 btn--link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {storeURL}
                  </a>
                </FormListItem>
              )}
              <ReauthorizeButton formName={formName} />
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="BigCommerce"
          href="https://support.ordoro.com/bigcommerce-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

BigCommerce.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(BigCommerce, setUpCartForm)
