import classNames from 'classnames'

import {
  getSupplierName,
  getIsFBA,
  supplierWarehousesSelector,
} from '../../../data/suppliers.js'
import Form from './Form.js'
import {
  suppliersFormSelector,
  errorsSelector,
  syncFBAInventory,
  saveSupplier,
  syncAWDInventory,
} from './suppliersFunctions.js'
import {useSelector} from '../../../store.js'
import {SUPPLIER_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import {COMMUNICATION_EMAIL} from '../../../common/constants/Suppliers.js'
import {
  WAREHOUSE_TYPE_AWD,
  WAREHOUSE_TYPE_FBA,
} from '../../../data/warehouses.js'

export default function EditForm({supplier}) {
  const form = useSelector(suppliersFormSelector)
  const errors = useSelector(errorsSelector)
  const {
    [WAREHOUSE_TYPE_FBA]: fbaWarehouse,
    [WAREHOUSE_TYPE_AWD]: awdWarehouse,
  } = useSelector((state) =>
    supplierWarehousesSelector(state, {supplierID: supplier.id}),
  )

  const isExpanded = supplier.id === form.id
  const editLink = `${SUPPLIER_SETTINGS_LINK}/${supplier.id}`
  const isFBASupplier = getIsFBA(supplier)
  const isArchived = !!supplier.archive_date
  const emails = [
    supplier.dropshipment_routing_channel === COMMUNICATION_EMAIL &&
      supplier.dropshipment_routing_address,
    supplier.po_routing_channel === COMMUNICATION_EMAIL &&
      supplier.po_routing_address,
  ]
    .filter((v) => v)
    .reduce((prev, email) => {
      if (!prev.includes(email)) {
        prev.push(email)
      }

      return prev
    }, [])
    .join(', ')

  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
        archived: isArchived,
      })}
    >
      {isFBASupplier && (
        <div>
          <span className="medium-10 small-8 columns">
            <strong className="make-block">{getSupplierName(supplier)}</strong>
            {fbaWarehouse && (
              <p className="make-smaller make-medium-grey margin-top-3 margin-bottom-0">
                {'FBA inventory is syncing with '}
                <a
                  className="inline-text-button darker with-underline"
                  href={`#/settings/warehouse/${fbaWarehouse.id}`}
                >
                  {fbaWarehouse.address.name}
                </a>
                .
              </p>
            )}
            {awdWarehouse && (
              <p className="make-smaller make-medium-grey margin-top-3 margin-bottom-0">
                {'AWD inventory is syncing with '}
                <a
                  className="inline-text-button darker with-underline"
                  href={`#/settings/warehouse/${awdWarehouse.id}`}
                >
                  {awdWarehouse.address.name}
                </a>
                .
              </p>
            )}
            {!isArchived && (
              <div className="margin-top-5">
                {!fbaWarehouse && (
                  <button
                    className="btn btn--primary btn--primary-alt btn--x-sm margin-right-10"
                    onClick={() => syncFBAInventory(supplier.id)}
                  >
                    Sync FBA Inventory
                  </button>
                )}
                {!awdWarehouse && (
                  <button
                    className="btn btn--primary btn--primary-alt-grey btn--x-sm"
                    onClick={() => syncAWDInventory(supplier.id)}
                  >
                    Sync AWD Inventory
                  </button>
                )}
              </div>
            )}
          </span>
          {isArchived ? (
            <em className="medium-2 columns right-aligned-text right">
              Archived
            </em>
          ) : (
            <span className="medium-2 small-4 columns right right-aligned-text">
              <a
                type="button"
                className="inline-text-button settings-list-item-button"
                href={editLink}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      )}
      {!isFBASupplier && (
        <div>
          <strong className="settings-list-item-title medium-5 small-8 columns">
            {getSupplierName(supplier)}
          </strong>
          {emails && (
            <span className="settings-list-item-desc supplier-email medium-5 hide-for-small columns">
              {emails}
            </span>
          )}
          {isArchived ? (
            <em className="medium-2 columns right-aligned-text right">
              Archived
            </em>
          ) : (
            <span className="medium-2 small-4 columns right right-aligned-text">
              <a
                type="button"
                className="inline-text-button settings-list-item-button"
                href={editLink}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      )}
      {isExpanded && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {errors.serverError && (
              <small className="error error-message">
                {errors.serverError}
              </small>
            )}
            <Form />
            <div className="clear-left">
              <div className="medium-5 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  disabled={errors.preventSave || form.isSaving}
                  onClick={saveSupplier}
                >
                  Save
                </button>
                <a
                  href="#/settings/supplier"
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </li>
  )
}
