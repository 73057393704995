import classNames from 'classnames'

import DatePicker from '../../../../common/components/DatePicker.js'
import TimeInput from '../../../../common/components/TimeInput.js'
import {useSelector} from '../../../../store.js'
import {
  updateModalForm,
  errorsSelector,
  modalFormSelector,
} from './pickupModalFunctions.js'

export default function PickupDateTime() {
  const minDate = new Date()
  const {pickupDate, pickupTime, closeTime} = useSelector(modalFormSelector)
  const errors = useSelector(errorsSelector)

  return (
    <li className="list__item--form list__item--no-style flex">
      <div
        className={classNames('margin-right-15', {error: errors.pickupDate})}
      >
        <label className="clearfix" htmlFor="id_pickup_date">
          Pickup Date
        </label>
        <DatePicker
          id="id_pickup_date"
          onChange={(date) => updateModalForm({pickupDate: date})}
          selected={pickupDate}
          minDate={minDate}
        />
        {errors.pickupDate && (
          <small className="error error-message">{errors.pickupDate}</small>
        )}
      </div>
      <div
        className={classNames('margin-right-15', {error: errors.pickupTime})}
      >
        <label className="clearfix" htmlFor="id_earliest_time">
          Ready Time
        </label>
        <TimeInput
          id="earliest_time"
          value={pickupTime}
          onBlur={(time) =>
            updateModalForm({pickupTime: time}, {stickyProps: ['pickupTime']})
          }
        />
        {errors.pickupTime && (
          <small className="error error-message">{errors.pickupTime}</small>
        )}
      </div>
      <div className={classNames({error: errors.closeTime})}>
        <label className="clearfix" htmlFor="id_latest_time">
          Close Time
        </label>
        <TimeInput
          id="latest_time"
          value={closeTime}
          onBlur={(time) =>
            updateModalForm({closeTime: time}, {stickyProps: ['closeTime']})
          }
        />
        {errors.closeTime && (
          <small className="error error-message">{errors.closeTime}</small>
        )}
      </div>
    </li>
  )
}
