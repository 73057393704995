import {MO_STATUS_DISPLAY} from '../../common/constants/MOs.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Address from '../../common/components/Order/Address.js'
import Timeline from '../../common/components/Details/Timeline/index.js'
import {
  createMOSelector,
  createMOCommentGroupsSelector,
  createMOWarehouseSelector,
} from '../../data/mos.js'
import MOTags from '../MOListPage/MOTags.js'
import {showAddMOCommentModal} from '../MOListPage/Modals/AddMOCommentModal.js'
import {
  showMOManufacturerNotesModal,
  showMOInternalNotesModal,
  showMOWarehouseModal,
} from '../MOListPage/Modals/EditMOParamModal.js'
import {moDetailReferenceIDSelector} from './moDetailSelectors.js'
import Lines from './Lines.js'
import {useMemo} from 'react'
import {useSelector} from '../../store.js'

export default function Details() {
  const referenceID = useSelector(moDetailReferenceIDSelector)
  const moSelector = useMemo(() => createMOSelector(referenceID), [referenceID])
  const commentGroupsSelector = useMemo(
    () => createMOCommentGroupsSelector(moSelector),
    [moSelector],
  )
  const moWarehouseSelector = useMemo(
    () => createMOWarehouseSelector(moSelector),
    [moSelector],
  )
  const mo = useSelector(moSelector)
  const warehouse = useSelector(moWarehouseSelector)

  return (
    <>
      <div className="flex margin-bottom-25">
        <h2 className="page__title page__title--inner inline-block v-align-middle margin-right-15">
          <strong>{referenceID}</strong>
        </h2>
        {mo.tags.length !== 0 && (
          <div className="inline-block v-align-middle">
            <MOTags referenceID={referenceID} tags={mo.tags} />
          </div>
        )}
      </div>
      <div className="flex">
        <div className="medium-3 columns wrap__list--order-data padding-left-0">
          <dl className="list list--order-data">
            <dt className="list__title--order-data">MFG Order Summary</dt>
            <dd className="list__item--order-data">
              <strong>Created: </strong>
              <span className="fs-n1">
                <FormattedDate value={mo.created_date} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={mo.created_date} format="h:mma" />
              </span>
            </dd>
            <dd className="list__item--order-data">
              <strong>Updated: </strong>
              <span className="fs-n1">
                <FormattedDate value={mo.updated_date} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={mo.updated_date} format="h:mma" />
              </span>
            </dd>
            <dd className="list__item--order-data">
              <strong>Status: </strong>
              <span className="fs-n1">{MO_STATUS_DISPLAY[mo.status]}</span>
            </dd>
          </dl>
          <dl className="list list--order-data with-top-divider">
            <dt className="list__title--order-data">Notes for Manufacturer</dt>
            {mo.manufacturer_notes ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{mo.manufacturer_notes}</div>
                <ButtonLink
                  className="meta-modetail-button-edit-manufacturer-note"
                  onClick={() => showMOManufacturerNotesModal(referenceID)}
                >
                  Edit
                </ButtonLink>
              </dd>
            ) : (
              <dd className="list__item--order-data">
                <ButtonLink
                  className="meta-modetail-button-add-manufacturer-note"
                  onClick={() => showMOManufacturerNotesModal(referenceID)}
                >
                  Add note
                </ButtonLink>
              </dd>
            )}
          </dl>
          <dl className="list list--order-data with-top-divider">
            <dt className="list__title--order-data">Internal Notes</dt>
            {mo.internal_notes ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{mo.internal_notes}</div>
                <ButtonLink
                  className="meta-modetail-button-edit-internal-note"
                  onClick={() => showMOInternalNotesModal(referenceID)}
                >
                  Edit
                </ButtonLink>
              </dd>
            ) : (
              <dd className="list__item--order-data">
                <ButtonLink
                  className="meta-modetail-button-add-internal-note"
                  onClick={() => showMOInternalNotesModal(referenceID)}
                >
                  Add note
                </ButtonLink>
              </dd>
            )}
          </dl>
        </div>
        <div className="medium-4 columns wrap__list--order-data">
          <Address
            title="Assigned Warehouse"
            address={warehouse.address}
            onEdit={() => showMOWarehouseModal(referenceID)}
            noPhone
            noEmail
          />
        </div>
      </div>
      <hr className="hr--detail-page" />
      <Lines />
      <hr className="hr--detail-page" />
      <Timeline
        commentGroupsSelector={commentGroupsSelector}
        readOnly={false}
        addComment={() => showAddMOCommentModal(referenceID)}
      />
    </>
  )
}
