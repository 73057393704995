import PropTypes from 'prop-types'
import classNames from 'classnames'

import {dispatch, useSelector} from '../../../store.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {navigate} from '../../../redux/actions/ui/index.js'
import {
  useInventoryWritebackSelector,
  usesInventorySelector,
} from '../../../data/company.js'
import {
  hasSettingsPermissionSelector,
  isSuperuserSelector,
} from '../../../data/me.js'
import {cartAuthIntegrationIssueSelector} from '../../../redux/selectors/ui/dashboard.js'
import {
  cartSelector,
  autoSyncOrdersSelector,
  autoSyncProductsSelector,
  autoSyncProductImagesSelector,
  autoSyncInventorySelector,
  autoSyncFBAInventorySelector,
  autoSyncFBAOrdersSelector,
  isFBACartSelector,
  isAmazonCartSelector,
  useWebhooksSelector,
  usesNewOrderImportProcessSelector,
  canImportOrdersSelector,
  canImportProductImagesSelector,
  canImportProductsSelector,
  canExportProductsSelector,
  preventWriteBackSettingsAdjustmentSelector,
  autoSyncAWDInventorySelector,
  isAWDCartSelector,
} from '../../../data/carts.js'
import {showEditCartModal} from '../../settings/Modals/EditCartModal.js'
import {
  closeModal,
  updateCartForm,
  syncOrdersSelector,
  syncProductsSelector,
  syncProductImagesSelector,
  syncInventorySelector,
  syncFBAInventorySelector,
  syncFBAOrdersSelector,
  needsFulfillmentLatencySelector,
  syncAWDInventorySelector,
} from './cartSyncModalFunctions.js'

export default function Cart({cartID, index}) {
  const cart = useSelector((state) => cartSelector(state, {cartID}))
  const syncOrders = useSelector((state) => syncOrdersSelector(state, {cartID}))
  const syncProducts = useSelector((state) =>
    syncProductsSelector(state, {cartID}),
  )
  const syncProductImages = useSelector((state) =>
    syncProductImagesSelector(state, {cartID}),
  )
  const syncInventory = useSelector((state) =>
    syncInventorySelector(state, {cartID}),
  )
  const syncFBAInventory = useSelector((state) =>
    syncFBAInventorySelector(state, {cartID}),
  )
  const syncAWDInventory = useSelector((state) =>
    syncAWDInventorySelector(state, {cartID}),
  )
  const syncFBAOrders = useSelector((state) =>
    syncFBAOrdersSelector(state, {cartID}),
  )
  const autoSyncOrders = useSelector((state) =>
    autoSyncOrdersSelector(state, {cartID}),
  )
  const autoSyncProducts = useSelector((state) =>
    autoSyncProductsSelector(state, {cartID}),
  )
  const autoSyncProductImages = useSelector((state) =>
    autoSyncProductImagesSelector(state, {cartID}),
  )
  const autoSyncInventory = useSelector((state) =>
    autoSyncInventorySelector(state, {cartID}),
  )
  const autoSyncFBAInventory = useSelector((state) =>
    autoSyncFBAInventorySelector(state, {cartID}),
  )
  const autoSyncAWDInventory = useSelector((state) =>
    autoSyncAWDInventorySelector(state, {cartID}),
  )
  const autoSyncFBAOrders = useSelector((state) =>
    autoSyncFBAOrdersSelector(state, {cartID}),
  )
  const isFBACart = useSelector((state) => isFBACartSelector(state, {cartID}))
  const isAWDCart = useSelector((state) => isAWDCartSelector(state, {cartID}))
  const isAmazonCart = useSelector((state) =>
    isAmazonCartSelector(state, {cartID}),
  )
  const usesInventory = useSelector(usesInventorySelector)
  const useWebhooks = useSelector((state) =>
    useWebhooksSelector(state, {cartID}),
  )
  const needsFulfillmentLatency = useSelector((state) =>
    needsFulfillmentLatencySelector(state, {cartID}),
  )
  const usesNewOrderImportProcess = useSelector((state) =>
    usesNewOrderImportProcessSelector(state, {cartID}),
  )
  const canImportProductImages = useSelector((state) =>
    canImportProductImagesSelector(state, {cartID}),
  )
  const integrationIssue = useSelector((state) =>
    cartAuthIntegrationIssueSelector(state, {cartID}),
  )
  const canImportOrders = useSelector((state) =>
    canImportOrdersSelector(state, {cartID}),
  )
  const canImportProducts = useSelector((state) =>
    canImportProductsSelector(state, {cartID}),
  )
  const canExportProducts = useSelector((state) =>
    canExportProductsSelector(state, {cartID}),
  )
  const preventWriteBackSettingsAdjustment = useSelector((state) =>
    preventWriteBackSettingsAdjustmentSelector(state, {cartID}),
  )
  const isSuperuser = useSelector(isSuperuserSelector)
  const hasSettingsPermission = useSelector(hasSettingsPermissionSelector)
  const useInventoryWriteback = useSelector(useInventoryWritebackSelector)

  return (
    <li
      className={classNames(
        'form-list-item sync-cart-list-item wrap--btn--edit-icon',
        {
          even: index % 2 === 0,
          odd: index % 2 !== 0,
        },
      )}
    >
      <div
        className={classNames(
          'panel clearfix',
          integrationIssue && 'panel--warning margin-bottom-25',
        )}
      >
        {needsFulfillmentLatency && (
          <div className="alert alert--standard alert--sm align-center fs-n1 margin-bottom-10">
            <span className="fs-n1 margin-bottom-0">
              <strong>
                Do you use extended handling times (fulfillment latency) for
                your Amazon listings?
              </strong>
              {' Check out '}
              <a
                href="https://support.ordoro.com/how-do-i-update-the-amazon-fulfillment-latency-in-ordoro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                this article
              </a>
              {' before writing back inventory.'}
            </span>
          </div>
        )}
        <dl>
          <dt>
            <span className="v-align-middle margin-right-5">{cart.name}</span>
            {integrationIssue && (
              <ButtonLink
                onClick={() => {
                  dispatch(navigate(integrationIssue.link))

                  closeModal()
                }}
              >
                <span className="label__callout label__callout--yellow v-align-middle margin-right-3">
                  Reauthorize
                </span>
              </ButtonLink>
            )}
            {hasSettingsPermission && (
              <ButtonLink
                className="no-underline btn--edit-icon"
                title="Edit this sales channel"
                onClick={() => {
                  showEditCartModal({cartID: cart.id})
                }}
              >
                <span
                  className="i-pencil fs-00 lh-sm v-align-middle"
                  aria-hidden="true"
                />
              </ButtonLink>
            )}
          </dt>
          {canImportOrders && (
            <>
              {useWebhooks && (
                <dd className="clear-both fs-00 lh-sm">
                  <div className="inline-block v-align-middle lh-sm">
                    <span className="i-instasync inline-block v-align-middle fs-00" />
                    <span className="fs-n0">Order Instasync</span>
                  </div>
                </dd>
              )}
              <dd
                className={classNames('left fs-00 lh-sm', {
                  autosynced: autoSyncOrders,
                })}
              >
                <label className="unbold-label margin-bottom-0">
                  <input
                    type="checkbox"
                    className="checkbox-sm margin-bottom-0 margin-right-3"
                    checked={syncOrders}
                    onChange={(event) =>
                      updateCartForm(cartID, {
                        syncOrders: event.target.checked,
                      })
                    }
                  />
                  <span className="fs-n0">Import Orders</span>
                  <span
                    className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                    aria-hidden="true"
                  />
                </label>
              </dd>
            </>
          )}
          {usesInventory && canExportProducts && (
            <dd
              className={classNames('left', {
                autosynced: autoSyncInventory,
                'wrap--locked-element tooltip--top':
                  preventWriteBackSettingsAdjustment,
              })}
              data-hint={
                preventWriteBackSettingsAdjustment
                  ? useInventoryWriteback
                    ? 'This configuration is locked.'
                    : 'Contact us to enable inventory writeback'
                  : autoSyncInventory
                    ? 'You can uncheck this locked configuration to sync other sales channels.'
                    : null
              }
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncInventory}
                  disabled={
                    !isSuperuser &&
                    preventWriteBackSettingsAdjustment &&
                    !autoSyncInventory
                  }
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncInventory: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Write Inventory Back</span>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
                {preventWriteBackSettingsAdjustment && (
                  <span
                    className="i-lock-closed i--v-align i--lh-0 fs-01 lh-0 margin-left-3 op-75"
                    aria-hidden="true"
                  />
                )}
              </label>
            </dd>
          )}
          {isFBACart && canImportProducts && (
            <dd
              className={classNames('left clear-both fba-sync', {
                autosynced: autoSyncFBAInventory,
              })}
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncFBAInventory}
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncFBAInventory: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Import FBA Inventory</span>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
              </label>
            </dd>
          )}
          {isAWDCart && canImportProducts && (
            <dd
              className={classNames('left clear-both fba-sync', {
                autosynced: autoSyncAWDInventory,
              })}
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncAWDInventory}
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncAWDInventory: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Import AWD Inventory</span>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
              </label>
            </dd>
          )}
          {isAmazonCart && canImportOrders && (
            <dd
              className={classNames('left clear-both fba-sync', {
                autosynced: autoSyncFBAOrders,
              })}
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncFBAOrders}
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncFBAOrders: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Import FBA Orders</span>
                <a
                  className="btn btn--link v-align-base unbold margin-left-3"
                  href="https://support.ordoro.com/can-i-import-my-amazon-fba-orders-into-my-ordoro-account/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (What is this?)
                </a>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
              </label>
            </dd>
          )}
          {canImportProducts && (
            <dd
              className={classNames('left clear-both', {
                autosynced: autoSyncProducts,
              })}
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncProducts}
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncProducts: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Import Products</span>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
              </label>
              {!usesNewOrderImportProcess && syncProducts && (
                <div className="alert alert--warning margin-top-10">
                  <div className="fs-n1 lh-md">
                    <strong>
                      This sales channel cannot have products autosynced.
                    </strong>
                    <div>
                      Use the “Sync Once“ button to run a one-time sync.
                    </div>
                  </div>
                </div>
              )}
            </dd>
          )}
          {canImportProductImages && (
            <dd
              className={classNames('left clear-both', {
                autosynced: autoSyncProductImages,
              })}
            >
              <label className="unbold-label margin-bottom-0">
                <input
                  type="checkbox"
                  className="checkbox-sm margin-bottom-0 margin-right-3"
                  checked={syncProductImages}
                  onChange={(event) =>
                    updateCartForm(cartID, {
                      syncProductImages: event.target.checked,
                    })
                  }
                />
                <span className="fs-n0">Import Product Images</span>
                <span
                  className="icon icon-block icon-autosync xx-sm margin-left-3 op-50"
                  aria-hidden="true"
                />
              </label>
            </dd>
          )}
        </dl>
      </div>
    </li>
  )
}

Cart.propTypes = {
  cartID: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
