import PropTypes from 'prop-types'
import CurrencyCodes from 'currency-codes/data.js'
import format from 'date-fns/format'

import {
  setForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  updateForm,
  getState,
  dispatch,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showEditAddressModal} from '../../Modals/EditAddressModal/index.js'
import verde from '../../../common/verde.js'
import {setShipper, shipperSelector} from '../../../data/shippers.js'
import {goToShipperSettings} from '../../../redux/actions/ui/settings/shippers/index.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  isAddressComplete,
  isPositiveNumeric,
  isPresent,
} from '../../../common/utils.js'
import {AddressShape, DateShape} from '../../../common/PropTypes.js'
import RowAddress from '../../../common/components/List/RowAddress.js'
import Select from '../../../common/components/Select.js'
import TextInput from '../../../common/components/TextInput.js'
import DatePicker from '../../../common/components/DatePicker.js'
import Radio from '../../../common/components/Radio.js'
import {Address, pickAddress} from '../../../common/address.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {
  defaultWarehouseSelector,
  warehouseAddressSelector,
} from '../../../data/warehouses.js'
import {
  companyContactSelector,
  companyNameSelector,
} from '../../../data/company.js'
import {FedExEULA} from './FedExEULA.js'

const MODAL_FORM = 'FEDEX_AUTH_MODAL'

const AUTH_METHOD_PIN = 'pin'
const AUTH_METHOD_INVOICE = 'invoice'
const AUTH_METHOD_SUPPORT = 'support'
const PIN_METHOD_SMS = 'SMS'
const PIN_METHOD_CALL = 'CALL'
const PIN_METHOD_EMAIL = 'EMAIL'

export function showFedExAuthModal(shipperID) {
  const shipper = shipperSelector(getState(), {shipperID})
  const accountNumber = (shipper && shipper.vendor_config.account_number) || ''
  const companyName = companyNameSelector(getState())
  const contact = companyContactSelector(getState())
  const defaultWarehouse = defaultWarehouseSelector(getState()) || {}

  const address = pickAddress({
    ...new Address(),
    ...(defaultWarehouse
      ? warehouseAddressSelector(getState(), {warehouseID: defaultWarehouse.id})
      : undefined),
    name: contact,
    company: companyName,
  })

  setForm(MODAL_FORM, {
    shipperID,
    authMethod: AUTH_METHOD_PIN,
    pinMethod: PIN_METHOD_SMS,
    accountNumber,
    address,
    isResidential: false,
    agreeToEULA: false,
    pin: '',
    invoiceNumber: '',
    invoiceCurrency: 'USD',
    invoiceDate: new Date(),
    invoiceAmount: '0.00',
    existingAccount: false,
    preventSave: false,
    isSaving: false,
    serverError: null,
    hasReachedRockBottom: false,
  })
}

export function updateFedExAuthModal(updates, meta) {
  updateForm(MODAL_FORM, updates, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function errorsSelector(state) {
  const form = modalFormSelector(state)
  const errors = {}

  if (!isPresent(form.accountNumber)) {
    errors.accountNumber = 'Account number is required'
  }

  if (form.accountAuthToken) {
    if (form.waitingForPIN && form.authMethod !== AUTH_METHOD_SUPPORT) {
      if (!isPositiveNumeric(form.pin)) {
        errors.pin = 'PIN is required'
      }
    } else if (form.authMethod === AUTH_METHOD_PIN) {
      // nothing to get wrong
    } else if (form.authMethod === AUTH_METHOD_INVOICE) {
      if (!isPositiveNumeric(form.invoiceNumber)) {
        errors.invoiceNumber = 'Invoice Number is required'
      }
      if (!form.invoiceDate) {
        errors.invoiceDate = 'Invoice Date is required'
      }
      if (!isPositiveNumeric(form.invoiceAmount)) {
        errors.invoiceAmount = 'Invoice Amount is required'
      }
    }
  } else {
    if (!isAddressComplete(form.address)) {
      errors.address = 'Address is required'
    }

    if (!form.agreeToEULA) {
      errors.agreeToEULA = 'Agreeing to EULA is required'
    }

    if (!form.hasReachedRockBottom) {
      errors.hasReachedRockBottom = 'Please read the EULA'
    }
  }

  if (Object.keys(errors).length) {
    errors.preventSave = true
  }

  return errors
}

function editAddress() {
  const form = modalFormSelector(getState())

  showEditAddressModal({
    hasAddressShortcuts: true,
    address: form.address,
    willValidate: !!form.address.validation,
    toastMessage: 'Address was successfully updated.',
    onSave: (address) =>
      updateFedExAuthModal({
        address,
      }),
    markAllChanged: true,
    required: ['name', 'street1', 'city', 'state', 'zip', 'country'],
  })
}

function paramsSelector(state) {
  const form = modalFormSelector(state)
  const customer_name = form.address.name || form.address.company

  if (form.accountAuthToken && form.authMethod !== AUTH_METHOD_SUPPORT) {
    if (form.waitingForPIN) {
      return [
        'pin/validation',
        {
          account_number: form.accountNumber,
          pin: form.pin,
          account_auth_token: form.accountAuthToken,
          transaction_id: form.transactionID,
          customer_name,
        },
      ]
    } else if (form.authMethod === AUTH_METHOD_PIN) {
      return [
        'pin/generate',
        {
          account_auth_token: form.accountAuthToken,
          transaction_id: form.transactionID,
          option: form.pinMethod,
        },
      ]
    } else if (form.authMethod === AUTH_METHOD_INVOICE) {
      return [
        'invoice/validation',
        {
          account_number: form.accountNumber,
          invoice: {
            number: Number(form.invoiceNumber),
            currency: form.invoiceCurrency,
            date: format(form.invoiceDate, 'yyyy-MM-dd'),
            amount: Number(form.invoiceAmount),
          },
          account_auth_token: form.accountAuthToken,
          transaction_id: form.transactionID,
          customer_name,
        },
      ]
    }
  } else {
    return [
      'address/validation',
      {
        account_number: form.accountNumber,
        residential: form.isResidential,
        address: pickAddress(form.address),
        customer_name,
      },
    ]
  }
}

export async function processNextStep() {
  try {
    const [path, params] = paramsSelector(getState())

    updateFedExAuthModal({
      serverError: null,
      isSaving: true,
    })

    const {json} = await verde.post(`/fedex/${path}`, params)

    if (json.transactionId) {
      updateFedExAuthModal({
        transactionID: json.transactionId,
      })
    }

    if (json.output) {
      if (
        Array.isArray(json.output.mfaOptions) &&
        json.output.mfaOptions.length
      ) {
        const mfaOption = json.output.mfaOptions[0]
        const canInvoice = !!mfaOption.options.invoice
        const pinMethods = mfaOption.options.secureCode || []
        const canPINSMS =
          pinMethods.includes(PIN_METHOD_SMS) && !!mfaOption.phoneNumber
        const canPINEMail =
          pinMethods.includes(PIN_METHOD_EMAIL) && !!mfaOption.email
        const canPINCall =
          pinMethods.includes(PIN_METHOD_CALL) && !!mfaOption.phoneNumber

        updateFedExAuthModal({
          accountAuthToken: mfaOption.accountAuthToken,
          pinPhone: mfaOption.phoneNumber,
          pinEmail: mfaOption.email,
          canInvoice,
          canPINSMS,
          canPINEMail,
          canPINCall,
          pinMethod: canPINSMS
            ? PIN_METHOD_SMS
            : canPINEMail
              ? PIN_METHOD_EMAIL
              : PIN_METHOD_CALL,
          authMethod: pinMethods.length
            ? AUTH_METHOD_PIN
            : canInvoice
              ? AUTH_METHOD_INVOICE
              : AUTH_METHOD_SUPPORT,
        })
      } else if (json.output.status) {
        updateFedExAuthModal({
          waitingForPIN: true,
        })
      }

      updateFedExAuthModal({
        isSaving: false,
      })
    } else if (json.id) {
      setShipper(json)

      dispatch(goToShipperSettings())

      showMessageToast('FedEx account linked with Ordoro')

      closeModal()
    }
  } catch (err) {
    updateFedExAuthModal({
      serverError: err.error_message || err.message,
      isSaving: false,
    })
  }
}

const shipperCurrencyOptions = CurrencyCodes.map(({currency, code}) => (
  <option key={code} value={code}>{`${code}: ${currency}`}</option>
))

function FedExAuthModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Authorize FedEx Account"
      modalSize="md"
      onConfirm={() => processNextStep()}
      onCancel={() => closeModal()}
      confirmText={form.accountAuthToken ? 'Authorize' : 'I accept'}
      cancelText={form.accountAuthToken ? 'Close' : 'I decline'}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      {form.accountAuthToken ? (
        form.waitingForPIN ? (
          <ul className="list list--no-style margin-bottom-0">
            <li className="list__item fs-01 lh-md margin-bottom-15">
              {form.pinMethod === PIN_METHOD_CALL ? (
                <strong>You should be receiving a call from FedEx soon.</strong>
              ) : (
                <strong>
                  Check your{' '}
                  {form.pinMethod === PIN_METHOD_SMS ? 'phone' : 'email'} for
                  the PIN from FedEx.
                </strong>
              )}
            </li>
            <li className="list__item margin-bottom-10">
              <TextInput
                className="input--h-29 input--lg"
                label="Enter Authorization PIN"
                id="pin"
                value={form.pin}
                onChange={(pin) => updateFedExAuthModal({pin})}
                required
                errorMessage={errors.pin}
              />
            </li>
          </ul>
        ) : (
          <ul className="list list--no-style margin-bottom-0">
            <li className="list__item fs-01 lh-md margin-bottom-15">
              <strong>How do you want to authenticate with FedEx?</strong>
            </li>
            <li className="list__item margin-bottom-30">
              {(form.canPINSMS || form.canPINEMail || form.canPINCall) && (
                <div className="margin-bottom-10">
                  <Radio
                    label="Use a PIN"
                    name="auth_method"
                    id={AUTH_METHOD_PIN}
                    value={AUTH_METHOD_PIN}
                    checked={form.authMethod === AUTH_METHOD_PIN}
                    onClick={() =>
                      updateFedExAuthModal({authMethod: AUTH_METHOD_PIN})
                    }
                    mode="fancy"
                  />
                </div>
              )}
              {form.canInvoice && (
                <div className="margin-bottom-10">
                  <Radio
                    label="Use a recent invoice"
                    name="auth_method"
                    id={AUTH_METHOD_INVOICE}
                    value={AUTH_METHOD_INVOICE}
                    checked={form.authMethod === AUTH_METHOD_INVOICE}
                    onClick={() =>
                      updateFedExAuthModal({authMethod: AUTH_METHOD_INVOICE})
                    }
                    mode="fancy"
                  />
                </div>
              )}
              <Radio
                label="Call FedEx support"
                name="auth_method"
                id={AUTH_METHOD_SUPPORT}
                value={AUTH_METHOD_SUPPORT}
                checked={form.authMethod === AUTH_METHOD_SUPPORT}
                onClick={() =>
                  updateFedExAuthModal({authMethod: AUTH_METHOD_SUPPORT})
                }
                mode="fancy"
              />
            </li>
            {form.authMethod === AUTH_METHOD_PIN ? (
              <>
                <li className="list__item alert alert--neutral alert--lg margin-bottom-10 w-65">
                  <div className="fs-00 lh-md margin-bottom-10">
                    <strong>
                      Select the method you want FedEx to send the PIN:
                    </strong>
                  </div>
                  {form.canPINSMS && (
                    <div className="margin-bottom-10">
                      <Radio
                        label={`Via SMS text to ${form.pinPhone}`}
                        name="pin_method"
                        id={PIN_METHOD_SMS}
                        value={PIN_METHOD_SMS}
                        checked={form.pinMethod === PIN_METHOD_SMS}
                        onClick={() =>
                          updateFedExAuthModal({pinMethod: PIN_METHOD_SMS})
                        }
                        mode="fancy"
                      />
                    </div>
                  )}
                  {form.canPINEMail && (
                    <div className="margin-bottom-10">
                      <Radio
                        label={`Via email to ${form.pinEmail}`}
                        name="pin_method"
                        id={PIN_METHOD_EMAIL}
                        value={PIN_METHOD_EMAIL}
                        checked={form.pinMethod === PIN_METHOD_EMAIL}
                        onClick={() =>
                          updateFedExAuthModal({pinMethod: PIN_METHOD_EMAIL})
                        }
                        mode="fancy"
                      />
                    </div>
                  )}
                  {form.canPINCall && (
                    <Radio
                      label={`Via phone call to ${form.pinPhone}`}
                      name="pin_method"
                      id={PIN_METHOD_CALL}
                      value={PIN_METHOD_CALL}
                      checked={form.pinMethod === PIN_METHOD_CALL}
                      onClick={() =>
                        updateFedExAuthModal({pinMethod: PIN_METHOD_CALL})
                      }
                      mode="fancy"
                    />
                  )}
                </li>
              </>
            ) : form.authMethod === AUTH_METHOD_INVOICE ? (
              <>
                <li className="list__item alert alert--neutral alert--lg margin-bottom-20 w-65">
                  <ul className="list list--no-style">
                    <li className="list__item fs-00 lh-md margin-bottom-15">
                      <strong>
                        Fill in the following information using a FedEx invoice
                        that is less than 90 days old.
                      </strong>
                    </li>
                    <li className="list__item margin-bottom-15">
                      <TextInput
                        className="input--h-29 input--50"
                        label="Invoice Number"
                        id="invoice_number"
                        value={form.invoiceNumber}
                        onChange={(invoiceNumber) =>
                          updateFedExAuthModal({invoiceNumber})
                        }
                        required
                        errorMessage={errors.invoiceNumber}
                      />
                    </li>
                    <li className="list__item margin-bottom-15 w-50">
                      <DatePicker
                        id="invoice_date"
                        label="Invoice Date"
                        selected={form.invoiceDate}
                        onChange={(invoiceDate) =>
                          updateFedExAuthModal({invoiceDate})
                        }
                        required
                        errorMessage={errors.invoiceDate}
                      />
                    </li>
                    <li className="list__item margin-bottom-15">
                      <Select
                        className="w-50"
                        id="invoice_currency"
                        label="Invoice Currency"
                        value={form.invoiceCurrency}
                        onChange={(invoiceCurrency) =>
                          updateFedExAuthModal({invoiceCurrency})
                        }
                      >
                        {shipperCurrencyOptions}
                      </Select>
                    </li>
                    <li className="list__item margin-bottom-15">
                      <TextInput
                        className="input--h-29 input--lg"
                        label="Invoice Amount"
                        id="invoice_amount"
                        value={form.invoiceAmount}
                        onChange={(invoiceAmount) =>
                          updateFedExAuthModal({invoiceAmount})
                        }
                        required
                        errorMessage={errors.invoiceAmount}
                      />
                    </li>
                  </ul>
                </li>
              </>
            ) : form.authMethod === AUTH_METHOD_SUPPORT ? (
              <li className="alert alert--neutral alert--lg list__item margin-bottom-20 w-65">
                <div className="fs-00 lh-md margin-bottom-10">
                  Call FedEx Support at <strong>1 (800) 463-3339</strong> to
                  authorize your account over the phone.{' '}
                </div>
                <div className="fs-00 lh-md">
                  Once the call is finished, click the{' '}
                  <strong>Authorize</strong> button at the bottom of this window
                  to complete the process.
                </div>
              </li>
            ) : null}
          </ul>
        )
      ) : (
        <ul className="list list--no-style margin-bottom-0">
          <li className="list__item fs-01 lh-md margin-bottom-20">
            Provide us with your FedEx account number and associated billing
            address to start the authorization process.
          </li>
          <li className="list__item margin-bottom-30">
            <TextInput
              className="input--h-29 input--50"
              label="Account Number"
              id="account_number"
              value={form.accountNumber}
              onChange={(accountNumber) =>
                updateFedExAuthModal({accountNumber})
              }
              required
            />
          </li>
          <li className="list__item">
            <dl className="list--order-data list--no-style">
              <dt className="fs-00">Billing Address for FedEx Account</dt>
              <dd className="list__item--order-data">{form.address.name}</dd>
            </dl>
            <RowAddress address={form.address} noEmail noPhone />
            {errors.address && (
              <small className="error error-message">{errors.address}</small>
            )}
            <div className="margin-bottom-10">
              <button
                type="button"
                className="btn btn--link fs-n1"
                onClick={() => editAddress()}
              >
                Edit
              </button>
            </div>
          </li>
          <li className="list__item">
            <Checkbox
              label="Residential Address"
              id="is_residential"
              checked={form.isResidential}
              onChange={(isResidential) =>
                updateFedExAuthModal({isResidential})
              }
            />
          </li>
          <li className="list__item divider--top">
            <div className="fs-n0 lh-md margin-bottom-10">
              <strong>FedEx End-User License Agreement</strong>{' '}
            </div>
            <div className="flex">
              <div className="w-65 margin-right-15">
                <FedExEULA
                  onRockBottom={() =>
                    updateFedExAuthModal({hasReachedRockBottom: true})
                  }
                />
                {form.accountNumber && errors.hasReachedRockBottom && (
                  <small className="error error-message">
                    {errors.hasReachedRockBottom}
                  </small>
                )}
              </div>
              <div className="flex--justify-col w-20">
                <em className="fs-n1 lh-md">
                  Please scroll through and read the entire EULA before
                  accepting it.
                </em>
              </div>
            </div>
          </li>
          <li className="list__item">
            <Checkbox
              required
              label="I accept the FedEx EULA"
              id="agree_to_eula"
              checked={form.agreeToEULA}
              onChange={(agreeToEULA) => updateFedExAuthModal({agreeToEULA})}
              errorMessage={form.accountNumber && errors.agreeToEULA}
            />
          </li>
        </ul>
      )}
    </ConfirmModal>
  )
}

FedExAuthModal.propTypes = {
  form: PropTypes.shape({
    shipperID: PropTypes.number,
    authMethod: PropTypes.string.isRequired,
    pinMethod: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    isResidential: PropTypes.bool.isRequired,
    agreeToEULA: PropTypes.bool.isRequired,
    address: AddressShape,
    pin: PropTypes.string.isRequired,
    pinPhone: PropTypes.string,
    pinEmail: PropTypes.string,
    canInvoice: PropTypes.bool,
    canPINSMS: PropTypes.bool,
    canPINEMail: PropTypes.bool,
    canPINCall: PropTypes.bool,
    invoiceNumber: PropTypes.string.isRequired,
    invoiceCurrency: PropTypes.string.isRequired,
    invoiceDate: DateShape,
    invoiceAmount: PropTypes.string.isRequired,
    existingAccount: PropTypes.bool.isRequired,
    accountAuthToken: PropTypes.string,
    waitingForPIN: PropTypes.bool,
    preventSave: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(FedExAuthModal, modalFormSelector)
