import PropTypes from 'prop-types'
import {useEffect, useMemo} from 'react'
import classnames from 'classnames'

import {RateShape, BulkRateShape} from '../../../../common/PropTypes.js'
import {showMultiboxModal} from '../../../OrderListPage/Modals/MultiboxModal/index.js'
import ButtonRefresh from '../../../../common/components/Button/ButtonRefresh.js'
import RateOption from './RateOption.js'
import {formatMetaClassName} from '../useLabelProperty.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'
import {useSelector} from 'react-redux'
import {
  isMultiboxSelector,
  isSimpleRateBoxShapeSelector,
  labelShipperTypeSelector,
} from '../../../../data/labelInfos/index.js'

export default function RateSelect({
  value,
  hasErrors,
  hasWarnings,
  updated,
  loading,
  disabled,
  packageCount,
  canHaveMultibox,
  rates,
  refresh,
  onChange,
  currencySymbol,
}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const isSimpleRateBoxShape = useSelector((state) =>
    isSimpleRateBoxShapeSelector(state, {labelInfoID, shipperType}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )
  const disableMultibox = !isMultibox && isSimpleRateBoxShape

  // only rates for the current shipper
  rates = useMemo(
    () => rates.filter((rate) => rate.vendor === shipperType),
    [rates, shipperType],
  )
  const disabledRates = rates.filter((rate) => rate.disabled)
  const notDisabledRates = useMemo(
    () => rates.filter((rate) => !rate.disabled),
    [rates],
  )

  const hasNoRates = !loading && !disabled && rates.length === 0

  const hasOnlyDisabledRates =
    rates.length === disabledRates.length && rates.length > 0

  useEffect(() => {
    if (loading) {
      return
    }

    if (!value && notDisabledRates.length) {
      onChange(notDisabledRates[0])
    } else if (value && hasOnlyDisabledRates) {
      onChange(null)
    }
  }, [loading, hasOnlyDisabledRates, value, notDisabledRates])

  return (
    <div className={formatMetaClassName('rate_select')}>
      <label className="label--bold" htmlFor="rate-select">
        <span>
          Shipping Methods/Rates<span className="required">*</span>
        </span>
        {updated && (
          <span className="label__callout label__callout--blue margin-left-5">
            Updated
          </span>
        )}
      </label>
      <div className="flex">
        <select
          id="rate-select"
          value={value}
          onChange={(event) => {
            const key = event.target.value
            const rate = rates.find((r) => r.key === key)

            onChange(rate)
          }}
          className={classnames('select w-100 h-auto margin-right-3', {
            'select--updated': updated,
            'select--loading': loading,
            'select--error': hasErrors || hasOnlyDisabledRates,
            'select--warning':
              hasWarnings && !(hasErrors || hasOnlyDisabledRates),
          })}
          disabled={loading || disabled || rates.length === 0}
        >
          {hasNoRates ? (
            <option>No shipping methods</option>
          ) : (
            <>
              {hasOnlyDisabledRates && (
                <option key="incompatible">
                  No compatible shipping methods
                </option>
              )}
              {!loading &&
                !disabled &&
                !rates.find((rate) => rate.key === value) && (
                  <option key="invalid" value={value} disabled>
                    {value || ''}
                  </option>
                )}
              {rates.map((rate, index) => (
                <RateOption
                  rate={rate}
                  currencySymbol={currencySymbol}
                  key={`${rate.key}_${index}`}
                />
              ))}
            </>
          )}
        </select>
        <ButtonRefresh
          title="Refresh rates"
          loading={loading}
          onClick={refresh}
          className="meta-labelconfigform-button-refreshrates"
        />
        {canHaveMultibox && (
          <button
            className={classnames(
              'btn btn--primary btn--primary-ol btn--multibox meta-labelconfigform-button-multibox',
              {
                'btn--disabled': loading,
              },
            )}
            type="button"
            title={
              disableMultibox
                ? 'UPS Simple Rates do not support multiple packages'
                : 'Add multiple packages to this label'
            }
            onClick={() => showMultiboxModal(labelInfoID, shipperType)}
            disabled={disableMultibox}
          >
            {packageCount === 1 && (
              <span className="text--multibox fs-02">+</span>
            )}
            {packageCount > 1 && (
              <strong className="text--multibox fs-00 lh-lg">
                {packageCount}
              </strong>
            )}
          </button>
        )}
      </div>
    </div>
  )
}

RateSelect.propTypes = {
  rates: PropTypes.arrayOf(PropTypes.oneOfType([RateShape, BulkRateShape])),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,

  hasErrors: PropTypes.bool,
  hasWarnings: PropTypes.bool,
  updated: PropTypes.bool,
  loading: PropTypes.bool,
  packageCount: PropTypes.number,
  canHaveMultibox: PropTypes.bool,

  disabled: PropTypes.bool,

  currencySymbol: PropTypes.string.isRequired,
}
