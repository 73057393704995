import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import find from 'lodash/find.js'
import reduce from 'lodash/reduce.js'
import get from 'lodash/get.js'

import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {setForm, updateForm, formsSelector} from '../store.js'
import {getNounsFromValue, tokenizeOptions} from '../common/tokenizeOptions.js'
import {maxWarehouseCountSelector} from './company.js'

export const WAREHOUSES = 'WAREHOUSES'
export const WAREHOUSE_TYPE_FBA = 'fba'
export const WAREHOUSE_TYPE_AWD = 'awd'

export function setWarehouses(warehouses) {
  setForm(WAREHOUSES, keyBy(warehouses, 'id'))
}

export function setWarehouse(warehouse) {
  updateForm(WAREHOUSES, {[warehouse.id]: warehouse})
}

export async function getWarehouses() {
  try {
    const warehouses = await fetchAllAPI('/warehouse', 'warehouse')

    setWarehouses(warehouses)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting warehouses.',
        details: err.message || err.error_message,
      },
      err,
    )

    setWarehouses([])
  }
}

export function warehousesSelector(state) {
  return formsSelector(state)[WAREHOUSES] || warehousesSelector.default
}
warehousesSelector.default = {}

export function warehousesHaveLoadedSelector(state) {
  return !!formsSelector(state)[WAREHOUSES]
}

export function warehouseSelector(state, {warehouseID}) {
  return warehousesSelector(state)[warehouseID]
}

export function getWarehouseName(warehouse) {
  return warehouse ? warehouse.address.name : ''
}

export function warehouseNameSelector(state, {warehouseID}) {
  return getWarehouseName(warehouseSelector(state, {warehouseID}))
}

export function createWarehouseSelector(warehouseID) {
  return createSelector(
    warehousesSelector,
    (warehouses) => warehouses[warehouseID],
  )
}

export function getWarehouseAddress(warehouse) {
  return get(warehouse, 'address', {})
}

export function warehouseAddressSelector(state, {warehouseID}) {
  return getWarehouseAddress(warehouseSelector(state, {warehouseID}))
}

export const warehousesByNameSelector = createSelector(
  warehousesSelector,
  (warehouses) =>
    reduce(
      warehouses,
      (prev, warehouse) => {
        prev[getWarehouseName(warehouse)] = warehouse

        return prev
      },
      {},
    ),
)

export const warehousesSortedByNameSelector = createSelector(
  warehousesByNameSelector,
  (warehousesByName) =>
    Object.keys(warehousesByName)
      .sort()
      .map((name) => warehousesByName[name]),
)

export const warehouseOptionsSelector = createSelector(
  warehousesSortedByNameSelector,
  (warehouses) =>
    warehouses.map((warehouse) => ({
      value: warehouse.id,
      display: getWarehouseName(warehouse),
      entity: warehouse,
      type: WAREHOUSES,
      nouns: getNounsFromValue(
        `${getWarehouseName(warehouse)} ${warehouse.address.company || ''} ${
          warehouse.address.city || ''
        } ${warehouse.address.state || ''}`,
      ),
    })),
)

export const warehouseOptionTokensSelector = createSelector(
  warehouseOptionsSelector,
  (warehouseOptions) => tokenizeOptions(warehouseOptions),
)

export const nonFBAWarehousesSelector = createSelector(
  warehousesSortedByNameSelector,
  (warehouses) => warehouses.filter((warehouse) => !warehouse.cart),
)

export const nonFBAWarehouseOptionsSelector = createSelector(
  nonFBAWarehousesSelector,
  (warehouses) =>
    warehouses.map(({id, address}) => ({value: id, display: address.name})),
)

export const defaultWarehouseSelector = createSelector(
  warehousesSelector,
  (warehouses) =>
    find(warehouses, (warehouse) => warehouse.is_default_location),
)

export const needsWarehouseSelector = createSelector(
  defaultWarehouseSelector,
  warehousesHaveLoadedSelector,
  (warehouse, hasLoadedWarehouses) =>
    hasLoadedWarehouses &&
    (!warehouse || !warehouse.is_configured_for_shipping),
)

export function getIsFBAWarehouse(warehouse) {
  return !!get(warehouse, 'cart')
}

export function isFBAWarehouseSelector(state, {warehouseID}) {
  const warehouse = warehouseSelector(state, {warehouseID})

  return getIsFBAWarehouse(warehouse)
}

export const areAnyWarehousesConfiguredSelector = createSelector(
  warehousesSelector,
  (warehouses) =>
    !!find(warehouses, (warehouse) => warehouse.is_configured_for_shipping),
)

export const warehouseCountSelector = createSelector(
  warehousesSelector,
  (warehouses) => Object.keys(warehouses).length,
)

export function warehouseZipcodeSelector(state, {warehouseID}) {
  const warehouse = warehouseSelector(state, {warehouseID})

  return get(warehouse, 'address.zip', '')
}

export const firstWarehouseSelector = createSelector(
  warehousesSortedByNameSelector,
  (warehouses) => get(warehouses, '0'),
)

export const canHaveMoreWarehousesSelector = createSelector(
  warehouseCountSelector,
  (state) => maxWarehouseCountSelector(state),
  (warehouseCount, maxWarehouseCount) => warehouseCount < maxWarehouseCount,
)
