import {useEffect} from 'react'

import {MO_PLURAL_URI_COMPONENT} from '../../common/constants/MOs.js'
import {moSelector} from '../../data/mos.js'
import Modals from '../MOListPage/Modals/index.js'
import ActionsForSelected from '../MOListPage/ActionsForSelected.js'
import {
  moDetailFormSelector,
  moDetailReferenceIDSelector,
} from './moDetailSelectors.js'
import Details from './Details.js'
import {hasMOPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {onlyIfForm, useSelector} from '../../store.js'

function MODetailPage({form}) {
  const referenceID = useSelector(moDetailReferenceIDSelector)
  const mo = useSelector((state) => moSelector(state, {referenceID}))
  const hasMOPermission = useSelector(hasMOPermissionSelector)

  useEffect(() => {
    document.title = referenceID ? `${referenceID} - MFG Order` : 'MFG Order'
  }, [referenceID])

  if (!hasMOPermission) {
    return <NoPermissionsPage />
  }

  if (!referenceID) {
    return null
  }

  if (form.isLoading) {
    return (
      <div className="margin-top-40">
        <div className="loading align-center">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
            Loading...
          </strong>
        </div>
      </div>
    )
  }

  if (!mo) {
    return (
      <dl className="list--empty-content">
        <dt className="fs-02 margin-bottom-10">
          <span>MFG Order ‘{referenceID}’ could not be found</span>
        </dt>
      </dl>
    )
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <li className="inline-block v-align-base">
                  <a
                    href={`#/${MO_PLURAL_URI_COMPONENT}`}
                    className="btn btn--action"
                    title="Back to MFG Orders"
                  >
                    &#8592; MFG Orders
                  </a>
                </li>
                <ActionsForSelected referenceIDs={[referenceID]} />
              </ul>
            </div>
            <hr className="hr--detail-page margin-top-3" />
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <Details key={referenceID} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default onlyIfForm(MODetailPage, moDetailFormSelector)
