import useAnimateToFollow from './useAnimateToFollow.js'

export default function useScrollSize({ref}) {
  return useAnimateToFollow((prevValue) => {
    if (!ref.current) {
      return {width: 0, height: 0}
    }

    const currentValue = {
      width: ref.current.scrollWidth,
      height: ref.current.scrollHeight,
    }

    if (
      prevValue &&
      currentValue.width === prevValue.width &&
      currentValue.height === prevValue.height
    ) {
      return prevValue
    }

    return currentValue
  })
}
