import {moSelector} from '../../data/mos.js'
import {showDeleteMOLinesModal} from '../MOListPage/Modals/DeleteMOLinesModal.js'
import {showEditMOLineModal} from '../MOListPage/Modals/EditMOLineModal.js'
import {showFinishMOModal} from '../MOListPage/Modals/FinishMOModal.js'
import {updateMODetailForm} from './moDetailActions.js'
import {
  moDetailFormSelector,
  moDetailReferenceIDSelector,
} from './moDetailSelectors.js'
import Line from './Line.js'
import {useSelector} from '../../store.js'
import {Plural} from '../../common/components/Plural.js'
import ButtonAction from '../../common/components/Button/ButtonAction.js'
import Checkbox from '../../common/components/Checkbox.js'
import {useMemo} from 'react'

export default function Lines() {
  const referenceID = useSelector(moDetailReferenceIDSelector)
  const form = useSelector(moDetailFormSelector)
  const mo = useSelector((state) => moSelector(state, {referenceID}))
  const moLineIDs = useMemo(() => mo.lines.map(({id}) => id), [mo])
  const anySelected = form.selectedLineIDs.length > 0
  const allSelected = mo.lines.length === form.selectedLineIDs.length
  const indeterminate = !allSelected && anySelected

  return (
    <div>
      <div className="flex flex--justify margin-bottom-10">
        <div className="wrap--v-align-inner">
          <Checkbox
            id={`mo_select_all`}
            label="MFG Order Contents"
            className="margin-right-7"
            checked={allSelected}
            indeterminate={indeterminate}
            onClick={() =>
              updateMODetailForm({
                selectedLineIDs: allSelected ? [] : moLineIDs,
              })
            }
          />
        </div>
        <ul className="list list--no-style">
          <li className="inline-block fs-n0 margin-left-0">
            <ButtonAction
              onClick={() =>
                showFinishMOModal(
                  referenceID,
                  anySelected ? form.selectedLineIDs : moLineIDs,
                )
              }
            >
              Update Finished Qty
            </ButtonAction>
          </li>
          {anySelected && (
            <>
              <li className="inline-block fs-n0 margin-left-15">
                <ButtonAction
                  onClick={() =>
                    showEditMOLineModal(
                      referenceID,
                      form.selectedLineIDs,
                      'requested_quantity',
                    )
                  }
                >
                  Edit Requested Qty
                </ButtonAction>
              </li>
              <li className="inline-block fs-n0 margin-left-15">
                <ButtonAction
                  onClick={() =>
                    showDeleteMOLinesModal(referenceID, form.selectedLineIDs)
                  }
                >
                  Delete <Plural array={form.selectedLineIDs} word="Product" />
                </ButtonAction>
              </li>
            </>
          )}
        </ul>
      </div>

      {mo.lines.map((line, index) => (
        <Line
          key={line.id}
          index={index}
          line={line}
          referenceID={referenceID}
          selectedLineIDs={form.selectedLineIDs}
        />
      ))}
    </div>
  )
}
