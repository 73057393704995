import {useEffect} from 'react'

import {useSelector} from '../../store.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Quantity from '../../common/components/Quantity.js'
import {
  productSelector,
  productBOMComponentsSelector,
} from '../../data/products.js'
import {showDeleteMOLinesModal} from '../MOListPage/Modals/DeleteMOLinesModal.js'
import {showEditMOLineModal} from '../MOListPage/Modals/EditMOLineModal.js'
import {showFinishMOModal} from '../MOListPage/Modals/FinishMOModal.js'
import {handleMOLinesListSelection} from './moDetailActions.js'
import PopoverImage from '../../common/components/PopoverImage.js'
import Checkbox from '../../common/components/Checkbox.js'

export default function Line({line, index, referenceID, selectedLineIDs}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  const bomComponents = useSelector((state) =>
    productBOMComponentsSelector(state, {sku: line.sku}),
  )

  // filter out this line from selected lineIDs if removed
  useEffect(() => {
    return () => {
      handleMOLinesListSelection(line.id, false, false)
    }
  }, [])

  if (!product) {
    return null
  }

  const isDisabled = bomComponents.length === 0

  return (
    <>
      <div className="wrap--bom-product">
        <div className="flex--justify margin-bottom-20">
          <div className="wrap--v-align-inner">
            <Checkbox
              id={`mo_line_select_${line.id}`}
              label={`PRODUCT #${String(index + 1).padStart(2, '0')}`}
              className="margin-right-7"
              checked={selectedLineIDs.includes(line.id)}
              onClick={(checked, event) =>
                handleMOLinesListSelection(line.id, checked, event.shiftKey)
              }
              disabled={isDisabled}
            />
          </div>
          <div>
            <div className="flex">
              <button
                className="btn btn--link btn--sm "
                type="button"
                onClick={() => showDeleteMOLinesModal(referenceID, [line.id])}
              >
                Delete
              </button>
              <button
                className="btn btn--primary btn--x-sm btn--primary-ol"
                type="button"
                onClick={() => showEditMOLineModal(referenceID, [line.id])}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-70">
          <div className="mw-40 divider--right padding-right-20 margin-right-20">
            <dl className="list">
              <dt className="list__title fs-00 lh-md line-detail margin-bottom-0 flex wrap--lg-img-popover">
                <PopoverImage src={product.image_url} alt={product.name} />
                <a
                  className="btn--link darker"
                  href={`#/product/${encodeURIComponent(line.sku)}`}
                >
                  <strong className="fs-02 margin-bottom-3 block">
                    {product.name}
                  </strong>
                  <span className="block unbold">SKU: {line.sku}</span>
                  {product.upc && (
                    <span className="block unbold">UPC: {product.upc}</span>
                  )}
                </a>
              </dt>
            </dl>
          </div>
          <div className="mw-25 divider--right padding-right-20 margin-right-20">
            <dl className="list">
              <dt className="list__title fs-00 lh-md margin-bottom-0">
                Requested Qty
              </dt>
              <dd className="list__item margin-bottom-0">
                <ButtonLink
                  className="darker fs-03 lh-md v-align-top"
                  onClick={() =>
                    showEditMOLineModal(
                      referenceID,
                      [line.id],
                      'requested_quantity',
                    )
                  }
                  disabled={isDisabled}
                >
                  <strong>
                    <Quantity value={line.requested_quantity} />
                  </strong>
                </ButtonLink>
              </dd>
            </dl>
          </div>
          <div className="mw-25">
            <dl className="list">
              <dt className="list__title fs-00 lh-md margin-bottom-0">
                Finished Qty
              </dt>
              <dd className="list__item margin-bottom-0 flex">
                <ButtonLink
                  className="darker lh-md v-align-top fs-03"
                  onClick={() => showFinishMOModal(referenceID, [line.id])}
                  disabled={isDisabled}
                >
                  <strong className="margin-right-10">
                    <Quantity value={line.finished_quantity} />
                  </strong>
                </ButtonLink>
                <ButtonLink
                  className="light lh-md fs-n1 flex--justify-col"
                  onClick={() => showFinishMOModal(referenceID, [line.id])}
                  disabled={isDisabled}
                >
                  <span>Edit</span>
                </ButtonLink>
              </dd>
            </dl>
          </div>
        </div>
        <div className="flex margin-top-30 margin-bottom-20">
          <div className="w-80 divider--right padding-right-20 margin-right-20">
            <table className="table fs-00">
              <thead>
                <tr className="fs-00">
                  <th className="table__th table__th--md">
                    Components/Raw Materials
                  </th>
                  <th className="table__th table__th--md align-right">
                    Available Qty
                  </th>
                  <th className="table__th table__th--md align-right">
                    Qty Needed for Single BOM
                  </th>
                  <th className="table__th table__th--md align-right">
                    Total Components Qty
                  </th>
                </tr>
              </thead>

              {bomComponents.length ? (
                <tbody className="table__tbody table__tbody--lines">
                  {bomComponents.map((component) => (
                    <tr className="table__tr--return-orders" key={component.id}>
                      <td className="table__td table__td--md">
                        <div className="fs-00 flex lh-md w-85">
                          <PopoverImage
                            src={component.image_url}
                            alt={component.name}
                          />
                          <div>
                            <a
                              className="btn--link darker"
                              href={`#/product/${encodeURIComponent(component.sku)}`}
                            >
                              <strong className="fs-01 lh-md">
                                {component.name}
                              </strong>
                              <div>{component.sku}</div>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="table__td table__td--md align-right">
                        <span className="fs-02 text--md-grey">
                          <Quantity value={component.total_available} />
                        </span>
                      </td>
                      <td className="table__td table__td--md align-right">
                        <span className="fs-02 text--md-grey">
                          <Quantity value={component.quantity} />
                        </span>
                      </td>
                      <td className="table__td table__td--md align-right">
                        <strong className="fs-02">
                          {line.requested_quantity * component.quantity}
                        </strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>
          <div className="w-15 flex-grow flex--justify-col">
            <div>
              <div className="divider--bottom margin-bottom-20 padding-bottom-20">
                <div className="fs-00">
                  <strong>Manufacturing Notes:</strong>
                </div>
                {line.manufacturer_notes ? (
                  <>
                    <div className="fs-n1 ws-pre-wrap">
                      {line.manufacturer_notes}
                    </div>
                    <ButtonLink
                      className="fs-n1 meta-modetail-button-edit-manufacturer-notes"
                      onClick={() =>
                        showEditMOLineModal(
                          referenceID,
                          [line.id],
                          'manufacturer_notes',
                        )
                      }
                      disabled={isDisabled}
                    >
                      Edit
                    </ButtonLink>
                  </>
                ) : (
                  <ButtonLink
                    className="fs-n1 meta-modetail-button-add-manufacturer-notes"
                    onClick={() =>
                      showEditMOLineModal(
                        referenceID,
                        [line.id],
                        'manufacturer_notes',
                      )
                    }
                    disabled={isDisabled}
                  >
                    Add note
                  </ButtonLink>
                )}
              </div>
              <div>
                <div className="fs-00">
                  <strong>Internal Notes:</strong>
                </div>
                {line.internal_notes ? (
                  <>
                    <div className="fs-n1 ws-pre-wrap">
                      {line.internal_notes}
                    </div>
                    <ButtonLink
                      className="fs-n1 meta-modetail-button-edit-internal-notes"
                      onClick={() =>
                        showEditMOLineModal(
                          referenceID,
                          [line.id],
                          'internal_notes',
                        )
                      }
                      disabled={isDisabled}
                    >
                      Edit
                    </ButtonLink>
                  </>
                ) : (
                  <ButtonLink
                    className="fs-n1 meta-modetail-button-add-internal-notes"
                    onClick={() =>
                      showEditMOLineModal(
                        referenceID,
                        [line.id],
                        'internal_notes',
                      )
                    }
                    disabled={isDisabled}
                  >
                    Add note
                  </ButtonLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
