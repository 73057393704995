import {useEffect} from 'react'

function enableNoScroll(elementSelector, className) {
  const element = document.querySelector(elementSelector)

  const scrollTop = element.scrollTop

  element.classList.add(className)

  element.style.setProperty('top', `${-scrollTop}px`)
}

function disableNoScroll(elementSelector, className) {
  const element = document.querySelector(elementSelector)

  const scrollTop = parseInt(element.style.top) || 0

  element.classList.remove(className)

  element.style.removeProperty('top')

  element.scrollTop = -scrollTop
}

export function useScrollLock(
  elementSelector = 'html',
  className = 'no-scroll',
  dependencies = [],
) {
  useEffect(() => {
    enableNoScroll(elementSelector, className)

    return () => disableNoScroll(elementSelector, className)
  }, dependencies)
}
