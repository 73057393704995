import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import classNames from 'classnames'
import isValid from 'date-fns/isValid'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  MANUAL_TRACKING_SHIPPER_NAMES,
  mapShipperToTrackingCarrier,
} from '../../../common/constants/ShipperNames.js'
import apiverson from '../../../common/apiverson.js'
import {getRealDate} from '../../../common/date.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import DateTimeInput from '../../../common/components/DateTimeInput.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Select from '../../../common/components/Form/Select.js'
import TextInput from '../../../common/components/Form/TextInput.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  updateOrders,
  orderSelector,
  getHasTrackingInfo,
} from '../../../data/orders.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'

export const MODAL_FORM = 'EDIT_TRACKING_NUMBER_MODAL'

export function showEditTrackingNumberModal(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})
  const shippingInfo = get(order, 'shipping_info')
  const shippingCost = get(shippingInfo, 'cost')
  const shipperName = get(shippingInfo, 'carrier_name')
  const shipper = mapShipperToTrackingCarrier(shipperName) || 'usps'

  setForm(
    MODAL_FORM,
    {
      orderNumber,
      shipper,
      customShipper: shipperName && shipper === 'other' ? shipperName : '',
      shippingMethod: get(shippingInfo, 'shipping_method') || '',
      trackingNumber: get(shippingInfo, 'tracking_number') || '',
      trackingURL: get(shippingInfo, 'tracking_url') || '',
      shippingCost:
        isPositiveNumeric(shippingCost) && shippingCost !== 0
          ? String(shippingCost)
          : '',
      shipDate: get(shippingInfo, 'ship_date') || getRealDate().toISOString(),
      notifyBillto: false,
      notifyShipto: false,
      hasTrackingInfo: !!get(shippingInfo, 'tracking_number'),
      isRequesting: false,
      serverError: null,
    },
    {stickyPropsPrevent: shipperName ? ['shipper'] : []},
  )
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export const errorsSelector = createSelector(modalFormSelector, (form) => {
  const errors = {}

  if (!isPresent(form.shippingMethod)) {
    errors.shippingMethod = 'Shipping Method is required'
    errors.preventSave = true
  }

  if (!isPresent(form.trackingNumber)) {
    errors.trackingNumber = 'Tracking Number is required'
    errors.preventSave = true
  }

  if (isPresent(form.shippingCost) && !isPositiveNumeric(form.shippingCost)) {
    errors.shippingCost = 'Shipping Cost must be a positive number'
    errors.preventSave = true
  }

  if (!isPresent(form.shipDate)) {
    errors.shipDate = 'Ship Date is required'
    errors.preventSave = true
  } else if (!isValid(new Date(form.shipDate))) {
    errors.shipDate = 'Ship Date is invalid'
    errors.preventSave = true
  }

  return errors
})

export async function updateTrackingNumber() {
  try {
    const {
      orderNumber,
      shipper,
      customShipper,
      shippingMethod,
      trackingNumber,
      trackingURL,
      shippingCost,
      shipDate,
      notifyBillto,
      notifyShipto,
    } = modalFormSelector(getState())

    const order = orderSelector(getState(), {orderNumber})
    const hasTrackingInfo = getHasTrackingInfo(order)

    const params = {
      tracking_number: trackingNumber,
      tracking_url: trackingURL || undefined,
      cost: isPositiveNumeric(shippingCost) ? Number(shippingCost) : 0,
      ship_date: shipDate,
      shipping_method: shippingMethod,
      carrier_name: shipper === 'other' ? customShipper || 'other' : shipper,
      notify_bill_to: notifyBillto,
      notify_ship_to: notifyShipto,
      notify_cart: true,
    }

    updateModalForm({
      serverError: null,
      isRequesting: true,
    })

    if (hasTrackingInfo) {
      await apiverson.put(`${order.link}/shipping_info`, params)
    } else {
      await apiverson.post(`${order.link}/shipping_info`, params)
    }

    showMessageToast(
      `Tracking info for ${orderNumber} has been ${
        hasTrackingInfo ? 'updated' : 'created'
      }.`,
    )

    closeModal()

    await Promise.all([
      updateOrders([orderNumber]),
      refreshOrderListAndCounts(),
    ])
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isRequesting: false,
    })
  }
}

function EditTrackingNumberModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title={
        form.hasTrackingInfo ? 'Edit Tracking Info' : 'Enter Tracking Info'
      }
      isSaving={form.isRequesting}
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => updateTrackingNumber()}
      onCancel={() => closeModal()}
      error={form.serverError}
      isDisabled={errors.preventSave}
      preventInnerScroll
    >
      <ul className="list list--form">
        <li className="list__item--form flex">
          <Select
            className="wrap--input-half"
            label="Carrier"
            value={form.shipper}
            name="shipper"
            onChange={(shipper) =>
              updateModalForm({shipper}, {stickyProps: ['shipper']})
            }
          >
            {MANUAL_TRACKING_SHIPPER_NAMES.map(({value, display}) => (
              <option value={value} key={value}>
                {display}
              </option>
            ))}
          </Select>
        </li>
        {form.shipper === 'other' && (
          <li className="list__item--form">
            <TextInput
              noColumns
              label="Custom Carrier"
              name="shipping_method"
              value={form.customShipper}
              onChange={(customShipper) =>
                updateModalForm({
                  customShipper,
                  hasChanged_customShipper: true,
                })
              }
              errorMessage={
                form.hasChanged_customShipper && errors.customShipper
              }
            />
          </li>
        )}
        <li className="list__item--form">
          <TextInput
            noColumns
            label="Shipping Method"
            required
            name="shipping_method"
            value={form.shippingMethod}
            onChange={(shippingMethod) =>
              updateModalForm({
                shippingMethod,
                hasChanged_shippingMethod: true,
              })
            }
            errorMessage={
              form.hasChanged_shippingMethod && errors.shippingMethod
            }
          />
        </li>
        <li className="list__item--form">
          <TextInput
            noColumns
            label="Tracking Number"
            required
            name="tracking_number"
            value={form.trackingNumber}
            onChange={(trackingNumber) =>
              updateModalForm({
                trackingNumber,
                hasChanged_trackingNumber: true,
              })
            }
            errorMessage={
              form.hasChanged_trackingNumber && errors.trackingNumber
            }
          />
        </li>
        <li className="list__item--form">
          <TextInput
            noColumns
            label="Tracking URL"
            name="tracking_url"
            value={form.trackingURL}
            onChange={(trackingURL) =>
              updateModalForm({
                trackingURL,
                hasChanged_trackingURL: true,
              })
            }
            errorMessage={form.hasChanged_trackingURL && errors.trackingURL}
          />
        </li>
        <li className="list__item--form flex--justify">
          <TextInput
            className="wrap--input-half"
            noColumns
            label="Shipping Cost"
            name="shipping_cost"
            value={form.shippingCost}
            onChange={(shippingCost) =>
              updateModalForm({
                shippingCost,
                hasChanged_shippingCost: true,
              })
            }
            errorMessage={form.hasChanged_shippingCost && errors.shippingCost}
          />
          <div
            className={classNames('wrap--input-half wrap--modal-date-picker', {
              error: errors.shipDate,
            })}
          >
            <label htmlFor="id_ship_date">
              Ship Date<span className="required">*</span>
            </label>
            <DateTimeInput
              id="id_ship_date"
              value={form.shipDate}
              onDateChange={(shipDate) =>
                updateModalForm({
                  shipDate: shipDate || getRealDate().toISOString(),
                  hasChanged_shipDate: true,
                })
              }
            />
            {form.hasChanged_shipDate && errors.shipDate && (
              <small className="error">{errors.shipDate}</small>
            )}
          </div>
        </li>
        <li className="list__item--form margin-top-15 flex">
          <Checkbox
            className="margin-right-15"
            label="Notify Bill To"
            name="id_notify_billto"
            checked={form.notifyBillto}
            onChange={() => updateModalForm({notifyBillto: !form.notifyBillto})}
          />
          <Checkbox
            label="Notify Ship To"
            name="id_notify_shipto"
            checked={form.notifyShipto}
            onChange={() => updateModalForm({notifyShipto: !form.notifyShipto})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

export default onlyIfForm(EditTrackingNumberModal, modalFormSelector)
