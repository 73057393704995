import {useCallback, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'

import {updateForm, onlyIf} from '../../store.js'
import {areChildrenNotEmpty} from '../utils.js'
import {HK_CONFIRM, HK_UP, HK_DOWN} from '../constants/HotKeys.js'
import {HotKeyConnect} from './HotKeys.js'
import {setCurrentDropdown} from '../../redux/actions/ui/currentDropdown.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import useBoundingBox from './useBoundingBox.js'
import useScrollSize from './useScrollSize.js'
import useWindowSize from './useWindowSize.js'
import cn from '../className.js'
import {useScrollLock} from './useScrollLock.js'

export function useZropdownElement({ref, isSelected}) {
  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({block: 'nearest'})
    }
  }, [ref.current, isSelected])
}

export function useZropdown({
  dropdown,
  form,
  onChange,
  onSelect,
  autoFocus,
  closeOnConfirm,
}) {
  const onChangeInternal = useCallback(
    (value) => {
      updateForm(dropdown, {text: value})

      setCurrentDropdown(dropdown)

      if (onChange) {
        onChange(value)
      }
    },
    [onChange],
  )

  const onSelectInternal = useCallback(
    (result) => {
      if (onSelect) {
        onSelect(result)
      }
    },
    [onSelect],
  )

  const onConfirm = useCallback(() => {
    const option = form.results[form.selectedIndex]

    if (option && !option.disabled) {
      onSelectInternal(option)
    }

    if (closeOnConfirm) {
      setCurrentDropdown(null)
    }
  }, [form])

  const onUp = useCallback(() => {
    let validOptionIndex = form.selectedIndex

    while (validOptionIndex - 1 >= 0) {
      validOptionIndex -= 1

      const option = form.results[validOptionIndex]

      if (option.disabled) {
        continue
      }

      updateForm(dropdown, {selectedIndex: validOptionIndex})

      break
    }
  }, [form])

  const onDown = useCallback(() => {
    let validOptionIndex = form.selectedIndex

    while (validOptionIndex + 1 < form.results.length) {
      validOptionIndex += 1

      const option = form.results[validOptionIndex]

      if (option.disabled) {
        continue
      }

      updateForm(dropdown, {selectedIndex: validOptionIndex})

      break
    }
  }, [form])

  useEffect(() => {
    if (autoFocus) {
      setCurrentDropdown(dropdown)
    }
  }, [autoFocus])

  return {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown}
}

const MIN_HEIGHT = 57 * 3 // if we can see at least three items in dropdown
export const POP_DOWN = 'down'
export const POP_UP = 'up'
export const POP_LEFT = 'left'
export const POP_RIGHT = 'right'
export const POP_CENTER = 'center'

export function calculateDropdown({
  minimumHeight,
  positionVert,
  positionHorz,
  windowSize,
  boundingBox,
  contentWidth,
  contentHeight,
  headerHeight,
  footerHeight,
  centerBoth = false,
  border = 4, // size of dropdown black border (2 + 2)
  vertOffset = 13, // vertical offset from "parent" element
  horzOffset = 0, // horizontal offset from "parent" element
  edgeGap = 5, // size of buffer between edge of window and dropdown
}) {
  // check to see if parent is outside of the viewable area (scrolled out of view)
  const parentIsNotVisible =
    boundingBox.bottom < 0 || windowSize.height < boundingBox.top

  // the real size of the full dropdown if there is no space limit
  const fullDropdownHeight =
    headerHeight + contentHeight + footerHeight + border

  // gaps around UI
  const vertGap = vertOffset + edgeGap
  const horzGap = horzOffset + edgeGap

  // the amount of available space below the parent element
  const bottomSpace = windowSize.height - boundingBox.bottom - vertGap
  // the amount of available space above the parent element
  const topSpace = boundingBox.top - vertGap

  // minimum size of dropdown content
  const minimumSizeOfContent =
    headerHeight + minimumHeight + footerHeight + border

  // figure out where we want the dropdown above/below parent
  if (parentIsNotVisible) {
    // if we can't see the parent then just center it
    positionVert = POP_CENTER
  } else if (positionVert === POP_DOWN) {
    // if wanting below parent
    positionVert =
      // if it fits below, then put it there
      bottomSpace >= minimumSizeOfContent
        ? POP_DOWN
        : // check if it fits above
          topSpace >= minimumSizeOfContent
          ? POP_UP
          : // otherwise center it
            POP_CENTER
  } else {
    // otherwise we assume above parent
    positionVert =
      // if it fits above, then put it there
      topSpace >= minimumSizeOfContent
        ? POP_UP
        : // check if it first below
          bottomSpace >= minimumSizeOfContent
          ? POP_DOWN
          : // otherwise center it
            POP_CENTER
  }

  // the amount of available space to the right of the parent element
  const rightSpace = windowSize.width - boundingBox.left - horzGap
  // the amount of available space to the left of the parent element
  const leftSpace = boundingBox.right - horzGap

  const widthOfDropdown = contentWidth + border

  // figure out where we want the dropdown left/right of parent
  if (positionHorz === POP_RIGHT) {
    // if wanting right
    positionHorz =
      // if it fits right, then put it there
      rightSpace >= widthOfDropdown
        ? POP_RIGHT
        : // check if it fits left
          leftSpace >= widthOfDropdown
          ? POP_LEFT
          : // otherwise center it
            POP_CENTER
  } else {
    // otherwise we assume left
    positionHorz =
      // if it fits left, then put it there
      leftSpace >= widthOfDropdown
        ? POP_LEFT
        : // check if it first right
          rightSpace >= widthOfDropdown
          ? POP_RIGHT
          : // otherwise center it
            POP_CENTER
  }

  // center both if either one is centered
  if (
    centerBoth &&
    (positionVert === POP_CENTER || positionHorz === POP_CENTER)
  ) {
    positionVert = POP_CENTER
    positionHorz = POP_CENTER
  }

  // height available after accounting for gaps
  const heightAvailable =
    positionVert === POP_UP
      ? topSpace
      : positionVert === POP_DOWN
        ? bottomSpace
        : windowSize.height - edgeGap - edgeGap

  // if the full dropdown height can be shown then show it
  const forcedContentHeight =
    heightAvailable >= fullDropdownHeight
      ? // take full height of content
        contentHeight
      : // take space available and minus the fixed bits
        heightAvailable - headerHeight - footerHeight - border

  // width available after accounting for gaps
  const widthAvailable =
    positionHorz === POP_LEFT
      ? leftSpace
      : positionHorz === POP_RIGHT
        ? rightSpace
        : windowSize.width - edgeGap - edgeGap

  // if the full dropdown width can be shown then show it
  const forcedContentWidth =
    widthAvailable >= widthOfDropdown
      ? // take full width of content
        contentWidth
      : // take space available and minus the fixed bits
        widthAvailable - border

  // move the dropdown in the left/right direction
  const dropdownLeft =
    positionHorz === POP_LEFT
      ? // to the left of parent
        boundingBox.right - (forcedContentWidth + border + horzOffset)
      : positionHorz === POP_RIGHT
        ? // to the right of parent
          boundingBox.left + horzOffset
        : // center in window
          (windowSize.width - (forcedContentWidth + border)) / 2

  // move the dropdown in the top/down direction
  const dropdownTop =
    positionVert === POP_UP
      ? // above parent
        boundingBox.top -
        (forcedContentHeight +
          headerHeight +
          footerHeight +
          border +
          vertOffset)
      : positionVert === POP_DOWN
        ? // below parent
          boundingBox.bottom + vertOffset
        : // center in window
          (windowSize.height -
            (forcedContentHeight + headerHeight + footerHeight + border)) /
          2

  return {
    left: dropdownLeft,
    top: dropdownTop,
    contentWidth: forcedContentWidth,
    contentHeight: forcedContentHeight,
    positionVert,
    positionHorz,
  }
}

/**
 * Zropdown
 *
 * A Dynamic Dropdown
 *
 * @param {Object} props
 * @param {String} props.dropdown
 * @param {String} [props.id]
 * @param {String} [props.className]
 * @param {React.RefObject} props.parentRef
 * @param {Function} props.onConfirm
 * @param {Function} props.onUp
 * @param {Function} props.onDown
 * @param {Boolean} [props.preventClose]
 * @param {ReactNode} props.children
 * @param {ReactNode} [props.header]
 * @param {ReactNode} [props.footer]
 * @param {Boolean} [props.blurOnClose]
 * @param {Number} [props.minimumHeight=MIN_HEIGHT]
 * @param {String} [props.positionVert=POP_DOWN]
 * @param {String} [props.positionHorz=POP_RIGHT]
 * @returns {ReactNode}
 */
function Zropdown({
  dropdown,
  id,
  className,
  parentRef,
  onConfirm,
  onUp,
  onDown,
  preventClose,
  header,
  footer,
  blurOnOpen,
  minimumHeight = MIN_HEIGHT,
  positionVert = POP_DOWN,
  positionHorz = POP_RIGHT,
  centerBoth = true,
  children,
}) {
  className = className || ''

  useEffect(() => {
    if (blurOnOpen) {
      document.activeElement.blur()
    }
  }, [blurOnOpen])

  useScrollLock()

  const ref = useRef()
  const headerRef = useRef()
  const footerRef = useRef()
  const boundingBox = useBoundingBox({ref: parentRef})
  const {height: headerHeight} = useBoundingBox({ref: headerRef})
  const {height: footerHeight} = useBoundingBox({ref: footerRef})
  const {width: contentWidth, height: contentHeight} = useScrollSize({ref})
  const windowSize = useWindowSize()

  if (!areChildrenNotEmpty(children)) {
    return null
  }

  const calculated = calculateDropdown({
    minimumHeight,
    positionVert,
    positionHorz,
    windowSize,
    boundingBox,
    contentWidth,
    contentHeight,
    headerHeight,
    footerHeight,
    centerBoth,
  })

  // setup styles
  const style = {
    top: calculated.top,
    left: calculated.left,
    width: 'fit-content',
    height: 'fit-content',
  }

  const dropStyle = {
    width: calculated.contentWidth,
    height: calculated.contentHeight,
  }

  // hide dropdown until there is content size
  // otherwise we flash an empty dropdown for one frame
  const hideIt = !dropStyle.width || !dropStyle.height

  return ReactDOM.createPortal(
    <div
      id={id}
      className={cn`wrap--base-dropdown open ${className} ${hideIt && 'invisible'}`}
      style={style}
      data-dropdown={dropdown}
    >
      <div
        className={cn`wrap--dropdown-list ${{
          'wrap--dropdown-list--up': calculated.positionVert === POP_UP,
          'wrap--dropdown-list--down': calculated.positionVert === POP_DOWN,
          'wrap--dropdown-list--vert-center':
            calculated.positionVert === POP_CENTER,
          'wrap--dropdown-list--right': calculated.positionHorz === POP_RIGHT,
          'wrap--dropdown-list--left': calculated.positionHorz === POP_LEFT,
          'wrap--dropdown-list--horz-center':
            calculated.positionHorz === POP_CENTER,
        }}`}
        data-dropdown-prevent-close={preventClose}
      >
        {header && (
          <div className="dropdown-list--header" ref={headerRef}>
            {header}
          </div>
        )}
        <div
          className="list--dropdown list--dropdown-open"
          ref={ref}
          style={dropStyle}
        >
          {onConfirm && <HotKeyConnect code={HK_CONFIRM} func={onConfirm} />}
          {onUp && <HotKeyConnect code={HK_UP} func={onUp} />}
          {onDown && <HotKeyConnect code={HK_DOWN} func={onDown} />}
          <ul>{children}</ul>
        </div>
        {footer && (
          <div className="dropdown-list--footer" ref={footerRef}>
            {footer}
          </div>
        )}
      </div>
    </div>,
    document.querySelector('#dropdown-root'),
  )
}

export default onlyIf(
  Zropdown,
  (state, {dropdown, show}) =>
    show || dropdown === currentDropdownSelector(state),
)
