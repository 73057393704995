import PropTypes from 'prop-types'
import Checkbox from '../../../common/components/Checkbox.js'
import {updateForm, useForm} from '../../../store.js'
import FormListItem from '../Common/FormListItem.js'
import Warehouses from './Warehouses.js'
import {
  BIGCOMMERCE,
  CUSTOM_INTEGRATION,
  ETSY,
  SHIP_STATION,
  SHOPIFY,
} from '../../../common/constants/CartVendorOptions.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'

export default function SyncConfig({className, formName}) {
  const {
    default_product_sync,
    default_min_export_qty,
    import_zero_qty_lines,
    syncs_shipped_orders,
    vendor,
  } = useForm(formName)

  return (
    <FormListItem className={className}>
      <p className="fs-00 lh-md margin-bottom-10">
        <strong>Sync Settings for this Sales Channel</strong>
      </p>
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="default_product_sync"
        label="Import New Products with Inventory Sync Enabled"
        checked={default_product_sync}
        onChange={(checked) =>
          updateForm(formName, {default_product_sync: checked})
        }
      />
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="import_zero_qty_lines"
        label="Import Zero Quantity Lines"
        checked={import_zero_qty_lines}
        onChange={(checked) =>
          updateForm(formName, {import_zero_qty_lines: checked})
        }
        helpLink="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/#zeroqty"
        helpTitle="Learn more about importing zero quantity lines"
      />
      {[SHOPIFY, ETSY, BIGCOMMERCE].includes(vendor) && (
        <Checkbox
          labelClassName="flex margin-bottom-10"
          id="syncs_shipped_orders"
          label="Mark orders as shipped if order is shipped in Sales Channel"
          checked={syncs_shipped_orders}
          onChange={(checked) =>
            updateForm(formName, {syncs_shipped_orders: checked})
          }
        />
      )}
      <NumberInput
        id="default_min_export_qty"
        label="Default Minimum Export Quantity"
        value={default_min_export_qty}
        onChange={(value) =>
          updateForm(formName, {default_min_export_qty: value})
        }
      />
      {![SHIP_STATION, CUSTOM_INTEGRATION].includes(vendor) && (
        <Warehouses formName={formName} />
      )}
    </FormListItem>
  )
}

SyncConfig.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
